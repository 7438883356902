<div class="modal-action-buttons">
  <button
    *ngIf="!actionButtonBlocked"
    nbButton
    status="primary"
    nbSpinnerStatus="basic"
    (click)="actionCallback()"
    [nbSpinner]="actionButtonLoading"
    [disabled]="actionButtonLoading || actionButtonDisabled"
    [ngStyle]="{
      'backgroundColor': actionButtonTheme === 'default' ? 'var(--button-dark)' : 'var(--button-red)'
    }"
  >{{ actionButtonText | uppercase }}</button>
  <button nbButton (click)="cancelCallback()">{{ closeButtonText }}</button>
</div>
