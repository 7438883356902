import { createFeature, createReducer, on } from "@ngrx/store";
import { DataState } from "enum/data-state.enum";
import { DashboardResponse, DashboardSummary } from "models/dashboard";
import { AdminDashboardPageActions } from "store/actions/dashboard.actions";

export interface DashboardState {
  summary: DashboardSummary[];
  pageState: DataState;
  error: string | null;
}

const initialDashboardState: DashboardState = {
  summary: [],
  pageState: DataState.LOADED,
  error: null,
}

export const dashboardFeature = createFeature({
  name: 'dashboard',
  reducer: createReducer(
    initialDashboardState,
    on(AdminDashboardPageActions.getDashboardInfo, (state) => {
      return { ...state, pageState: DataState.LOADING };
    }),
    on(AdminDashboardPageActions.getDashboardInfoSuccess, (state, { payload }) => {
      const summary = transformDashboardResponse(payload);
      return { ...state, pageState: DataState.LOADED, summary };
    }),
    on(AdminDashboardPageActions.getDashboardInfoFailure, (state, { error }) => {
      return { ...state, pageState: DataState.ERROR, error };
    })
  ),
});

function transformDashboardResponse(payload: DashboardResponse[]): DashboardSummary[] {
  let transformedData: DashboardSummary[] = [];

  for (let partition of payload) {
    for (let [key, value] of Object.entries(partition.summary)) {
      let entity: Partial<DashboardSummary> = {};
      entity.type = partition.relate === "config" ? "option" : "text";
      entity.relate = partition.relate;
      entity.property = key;
      entity.data = value;
      transformedData.push(entity as DashboardSummary);
    }
  }

  return transformedData;
}
