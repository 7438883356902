import { NbToastrService } from "@nebular/theme";

export function showWarning(toastrService: NbToastrService, message: string) {
  toastrService.show(message, `Warning`, {
    status: 'warning',
    duration: 10000,
    preventDuplicates: true,
  });
}

export function showSuccess(toastrService: NbToastrService, message: string) {
  toastrService.show(message, `Success`, {
    status: 'success',
    duration: 5000,
  });
}

export function showError(toastrService: NbToastrService, message: string) {
  toastrService.show(message, `Error`, {
    status: 'danger',
    duration: 5000,
  });
}
