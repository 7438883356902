import { Pipe, PipeTransform } from '@angular/core';
import { PlaylistUploadCreationItem, ResourceEntry } from '../models';

@Pipe({
  name: 'videoDefaultSort'
})
export class VideoSortPipe implements PipeTransform {

  transform(array: ResourceEntry[] | null): ResourceEntry[] | null {
    if (array == null) return array;

    return array.sort((a: ResourceEntry, b: ResourceEntry) => {
      if (a.id > b.id) {
        return -1;
      } else if (a.id < b.id) {
        return 1;
      } else {
        return 0;
      }
    })
  }
}
