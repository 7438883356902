import { OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DashboardSummary } from 'models/dashboard';
import { DashboardConfigChange } from 'models/events';

@Component({
  selector: 'app-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss']
})
export class DashboardCardComponent implements OnInit{
  @Input() title: string;
  @Input("summary") dashboardCard: DashboardSummary;
  @Output() onConfigChange = new EventEmitter<DashboardConfigChange>();

  optionValue: number;

  ngOnInit(): void {
    this.optionValue = this.dashboardCard.data;
  }

  changeConfig(newValue: number) {
    const oldValue = this.optionValue;

    if (oldValue === newValue) return;

    this.onConfigChange.emit({
      property: this.dashboardCard.property,
      oldValue,
      newValue,
    });
    this.optionValue = newValue;
  }

}
