import { createFeature, createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import { DataState } from "enum/data-state.enum";
import { VideoPageActions, VideoUploadActions } from "store/actions/videos.actions";
import { EntityAdapter, EntityState, createEntityAdapter } from "@ngrx/entity";
import { PollingPayload, ResourceEntry, ResourceStatus, ResourceUploadPayload } from 'models/resource';
import { getStatusFromProgress } from "helpers/videoStatus.helper";
import { UploadStatus } from "enum/upload-status.enum";
import { VideoStatusCode } from '../../enum/video-status.code';

export interface ResourceState {
  resources: EntityState<ResourceEntry>;
  uploadProgress: EntityState<ResourceStatus>;
  pageState: DataState;
  error: string | null;
  authorizationCode: string | null | boolean;
}

function sortById(a: ResourceEntry, b: ResourceEntry): number {
  return + (a.id > b.id);
}

export const ResourceAdapter: EntityAdapter<ResourceEntry> =
  createEntityAdapter<ResourceEntry>({ sortComparer: sortById });

export const UploadAdapter: EntityAdapter<ResourceStatus> =
  createEntityAdapter<ResourceStatus>();

const initialResourcesState: ResourceState = {
  resources: ResourceAdapter.getInitialState(),
  uploadProgress: UploadAdapter.getInitialState(),
  pageState: DataState.LOADED,
  error: null,
  authorizationCode: null,
}

export const resourceFeature = createFeature({
  name: 'videos',
  reducer: createReducer(
    initialResourcesState,
    on(VideoPageActions.getVideos, (state) => {
      return { ...state, pageState: DataState.LOADING };
    }),
    on(VideoPageActions.getVideosSuccess, (state, { payload }) => {
      const resources = ResourceAdapter.setAll(payload.resources, state.resources);
      return { ...state, pageState: DataState.LOADED, resources };
    }),
    on(VideoPageActions.getVideosFailure, (state, { error }) => {
      return { ...state, pageState: DataState.ERROR, error };
    }),
    on(VideoPageActions.addVideo, (state, { payload }) => {
      const resources = ResourceAdapter.addOne(payload, state.resources);
      const uploadProgress = UploadAdapter.addOne({ id: payload.id, uploadStatus: 0, statusCode: VideoStatusCode.PENDING_UPLOADING }, state.uploadProgress);
      return { ...state, resources, uploadProgress };
    }),
    on(VideoPageActions.updateVideo, (state, { payload }) => {
      const resources = ResourceAdapter.updateOne({
        id: payload.id,
        changes: {
          name: payload.name,
          resourceCharacter: payload.resourceCharacter
        }
      }, state.resources);
      return { ...state, resources };
    }),
    on(VideoPageActions.removeVideo, (state, { id }) => {
      const resources = ResourceAdapter.removeOne(id, state.resources);
      return { ...state, resources };
    }),
    on(VideoPageActions.removeVideos, (state, { ids }) => {
      const resources = ResourceAdapter.removeMany(ids, state.resources);
      return { ...state, resources };
    }),
    on(VideoUploadActions.uploadProgressComplete, (state, { payload }) => {
      const {
        uploadStatus,
        length,
        size,
        thumbnail,
        statusCode,
        isVerticalOrientation
      } = payload;
      const changes = { uploadStatus, length, size, thumbnail, statusCode, isVerticalOrientation };
      const resources = ResourceAdapter.updateOne({ id: payload.id, changes }, state.resources);
      const uploadProgress = UploadAdapter.removeOne(payload.id, state.uploadProgress);
      return { ...state, uploadProgress, resources };
    }),
    on(VideoUploadActions.uploadProgressFailure, (state, { id }) => {
      const resources = ResourceAdapter.updateOne(
        { id, changes: { uploadStatus: -1, statusCode: 20 } },
        state.resources
      );
      const uploadProgress = UploadAdapter.removeOne(id, state.uploadProgress);
      return { ...state, uploadProgress, resources };
    }),
    on(VideoUploadActions.uploadUrlVideoFailed, (state, { id }) => {
      const resources = ResourceAdapter.updateOne(
        { id, changes: { uploadStatus: -1, statusCode: 20 } },
        state.resources
      );
      const uploadProgress = UploadAdapter.removeOne(id, state.uploadProgress);
      return { ...state, uploadProgress, resources };
    }),
    on(VideoUploadActions.uploadUrlVideo, (state, { payload, source }) => {
      if (payload?.entry?.id) {
        const resources = ResourceAdapter.updateOne(
          { id: payload.entry.id, changes: { uploadStatus: 0, statusCode: 1 } },
          state.resources
        );
        return { ...state, resources };
      }

      return state;
    }),
    on(VideoUploadActions.uploadProgressUpdate, (state, { payload }) => {
      return updateStateFromPolledData(state, payload);
    }),
    on(VideoUploadActions.authorizationRequired, (state, { authCode }) => {
      return { ...state, authorizationCode: authCode };
    }),
  ),
  extraSelectors: ({ selectResources, selectUploadProgress }) => ({
    getResourcePageStatistics: createSelector(
      selectResources,
      ({ ids, entities }) => {
        let resources: any = Object.values(entities).filter( Boolean );

        const uploadedResources = resources
          .filter((resource: ResourceEntry) => getStatusFromProgress(resource?.uploadStatus || -1) === UploadStatus.READY);

        const count = ids.length ?? 0;
        const length = resources.reduce((acc: number, current: ResourceEntry) => (acc += current?.length ?? 0), 0);
        const usedSpace = uploadedResources.reduce((acc: number, current: ResourceEntry) => (acc += current?.size ?? 0), 0);
        return { count, length, usedSpace }
      }
    ),
    getResourceUploadProgress: (props: { id: number }) =>
      createSelector(
        selectUploadProgress,
        selectResources,
        (upload_stat, resources) => {
          const progressFromHttpEvent = upload_stat.entities[props.id]?.uploadStatus;
          const progressFromDb = resources.entities[props.id]?.uploadStatus;
          return progressFromHttpEvent ?? progressFromDb ?? 0;
        }),
    getResourceStatusCode: (props: { id: number }) =>
      createSelector(
        selectUploadProgress,
        selectResources,
        (upload_stat, resources) => {
          const statusFromHttpEvent = upload_stat.entities[props.id]?.statusCode;
          const statusFromDb = resources.entities[props.id]?.statusCode;
          return statusFromHttpEvent ?? statusFromDb ?? VideoStatusCode.PREPARING;
        }),
    getProcessedVideosCount: createSelector(
      selectUploadProgress,
      (upload_stat) => upload_stat.ids.length
    )
  })
});

const selectResourcePageState = createFeatureSelector<ResourceState>('videos');

const selectResourceState = (state: ResourceState) => state.resources;
const selectUploadState = (state: ResourceState) => state.uploadProgress;

export const fromResources = ResourceAdapter.getSelectors(selectResourceState);
export const fromUpload = UploadAdapter.getSelectors(selectUploadState);

export const getAllResources = createSelector(
  selectResourcePageState,
  fromResources.selectAll,
);

export const getAllUploads = createSelector(
  selectResourcePageState,
  fromUpload.selectAll,
);

export const getResourcePageError = createSelector(
  selectResourcePageState,
  resourceFeature.selectError,
);

export const getResourcePageState = createSelector(
  selectResourcePageState,
  resourceFeature.selectPageState,
);

export const getAuthorizationCode = createSelector(
  selectResourcePageState,
  (state: ResourceState) => state.authorizationCode
);

function updateStateFromPolledData(state: ResourceState, pollingResponse: PollingPayload): ResourceState {
  let { resources, uploadProgress } = state;

  for (const [videoId, progressDetails] of Object.entries(pollingResponse)) {
    const id = parseInt(videoId);
    const { uploadStatus, size, length, thumbnail, statusCode, authCode } = progressDetails;
    if (authCode) {
      resources = ResourceAdapter.updateOne(
        { id, changes: { uploadStatus: -1, statusCode: VideoStatusCode.ERROR_ACCESS } },
        state.resources
      );
      uploadProgress = UploadAdapter.removeOne(id, uploadProgress);
    } else if (uploadStatus === -1) {
      resources = ResourceAdapter.updateOne(
        { id, changes: { uploadStatus: -1, statusCode: VideoStatusCode.ERROR_UPLOADING } },
        state.resources
      );
      uploadProgress = UploadAdapter.removeOne(id, uploadProgress);
    } else if (uploadStatus < 100) {
      uploadProgress = UploadAdapter.upsertOne(
        { id, uploadStatus, statusCode: statusCode || VideoStatusCode.UPLOADING },
        uploadProgress
      );
    } else if (uploadStatus === 100) {
      uploadProgress = UploadAdapter.removeOne(id, uploadProgress);
      resources = ResourceAdapter.updateOne({
        id, changes: { uploadStatus, thumbnail, length, size, statusCode }
      }, resources);
    }
  }

  return { ...state, resources, uploadProgress };
}
