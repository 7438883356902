import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paginate'
})
export class PaginatePipe implements PipeTransform {

  transform<T>(items: Array<T> | null, page_size: number, page_number: number): Array<T> {
    return items ? this.paginate(items, page_size, page_number) : null;
  }

  private paginate(array: any, page_size: number, page_number: number) {
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }
}
