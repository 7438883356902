<nb-tabset class="nb-tabset"
           appUploadDnd
           (changeTab)="onTabChange($event)"
           (filesDropped)="onFilesDropped($event)"
>
  <nb-tab tabTitle="FROM YOUTUBE" class="nb-tabset-tab youtube" active>
    <div class="form-group">
      <label for="videoUrls" class="nb-tabset-tab-input-label d-inline-block">Video URLs</label>
      <nb-icon
        status="basic"
        class="d-inline-block"
        icon="question-mark-circle-outline"
        data-toggle="tooltip"
        title="Please enter each YouTube URL on a new line"
      ></nb-icon>
      <textarea
        #YouTubeUrl
        autosize
        id="videoUrls"
        class="form-control rounded-1"
        [placeholder]="youtubePlaceholder"
        rows="4"
        [value]="urls_v"
        (keyup)="onUrlEnter(YouTubeUrl.value)"
        (change)="onUrlEnter(YouTubeUrl.value)"
        [(ngModel)]="urls_v"
      ></textarea>
    </div>
  </nb-tab>

  <nb-tab tabTitle="FROM TWITCH" class="nb-tabset-tab twitch" active>
    <div class="form-group">
      <label for="videoUrls" class="nb-tabset-tab-input-label d-inline-block">Video URLs</label>
      <nb-icon
        status="basic"
        class="d-inline-block"
        icon="question-mark-circle-outline"
        data-toggle="tooltip"
        title="Please enter each Twitch URL on a new line"
      ></nb-icon>
      <textarea
        #TwitchUrl
        autosize
        id="videoTwitchUrls"
        class="form-control rounded-1"
        [placeholder]="twitchPlaceholder"
        rows="4"
        [value]="urls_v"
        (keyup)="onUrlEnter(TwitchUrl.value)"
        (change)="onUrlEnter(TwitchUrl.value)"
        [(ngModel)]="urls_v"
      ></textarea>
    </div>
  </nb-tab>

  <nb-tab tabTitle="FROM PC" class="nb-tabset-tab local-storage">
    <label class="nb-tabset-tab-input-label">File From PC</label>
    <div
      appUploadDnd
      class="dropzone"
      (filesDropped)="onFilesDropped($event)"
      (click)="fileDropRef.click()"
    >
      <input
        hidden
        multiple
        type="file"
        accept=".mp4, .flv"
        (change)="onLocalFileSelect($event)"
        #fileDropRef
      />
      <img src="assets/DNDUpload.svg" class="mb-3" alt="Upload file">
      <h3>Drag and drop video files</h3>
      <h3>or</h3>
      <button
        nbButton
        status="basic"
        class="dropzone-select-file"
      >Select files
      </button>
    </div>

    <ng-container *ngFor="let file of local_files">
      <div [ngClass]="{'local-file': true,  'wrong-extension': !isRightFileFormat(file)}">
        <div class="d-flex justify-content-start align-items-center">
          <img src="assets/videofile.svg" alt="Your Video">
          <span>{{ file.name }}</span>
        </div>
        <div class="local-file-actions">
          <p>{{ file.size | videoSize }}</p>
          <nb-icon
            class="delete-chosen-file"
            icon="trash-outline"
            status="danger"
            (click)="deleteChosenFile(file)"
          ></nb-icon>
        </div>
      </div>
    </ng-container>

  </nb-tab>

  <nb-tab tabTitle="FROM YOUTUBE PLAYLIST" class="nb-tabset-tab youtube" active>
    <div class="form-group">
      <label for="videoPlaylist" class="nb-tabset-tab-input-label d-inline-block">Video URL</label>
      <nb-icon
        status="basic"
        class="d-inline-block"
        icon="question-mark-circle-outline"
        data-toggle="tooltip"
        title="Enter YouTube Playlist URL"
      />
      <input
        id="videoPlaylist"
        [placeholder]="youtubePlaylistPlaceholder"
        type="text"
        class="form-control character-form-input"
        maxlength="250"
        [value]="urls_v"
        (keyup)="onUrlEnter(YouTubeUrl.value)"
        (change)="onUrlEnter(YouTubeUrl.value)"
        [(ngModel)]="urls_v"
      >
    </div>
  </nb-tab>
</nb-tabset>

<app-authorization-code/>

<div class="mt-1 mb-1" *ngIf="urls.length >= limitUrlLinks || local_files.length >= limitLocalFiles">
  <div *ngIf="urls.length > limitUrlLinks">
    <span>You have entered {{urls.length}} link(s) for upload,
      but the maximum limit of URLs is {{limitUrlLinks}}.</span>
    <span>Please reduce URLs to start uploading</span>
  </div>
  <span *ngIf="local_files.length > limitLocalFiles">The maximum limit of {{limitLocalFiles}} files exceeded!</span>
</div>
<app-modal-action-buttons
  [action-button-text]="'Upload'"
  [actionCallback]="submit.bind(this)"
  [cancelCallback]="closeModal.bind(this)"
  [action-button-loading]="isLoading"
  [actionButtonDisabled]="urls.length > limitUrlLinks || local_files.length > limitLocalFiles"
>
</app-modal-action-buttons>
