import { Component } from '@angular/core';
import { NbToastrService, NbWindowService } from '@nebular/theme';
import { select, Store } from '@ngrx/store';
import { PlaylistModalComponent } from 'components/playlist-modal/playlist-modal.component';
import { buttonsConfig, Playlist, PlaylistCreation, ResourceEntry, Stream } from 'models';
import { Observable } from 'rxjs';
import { getAllResources } from 'store/reducers/videos.reducers';
import { PlaylistService } from '../../services/playlist.service';
import { ConfirmModalComponent } from '../../components/confirm-modal/confirm-modal.component';
import { PlaylistActions } from '../../store/actions/playlist.actions';
import { playlistFeature } from '../../store/reducers/playlist.reducers';
import { VideoPageActions } from '../../store/actions/videos.actions';
import { streamFeature } from '../../store/reducers/stream.reducers';
import { StreamStatus } from '../../enum/streams.enum';
import { showWarning } from 'helpers/nb';

@Component({
  selector: 'app-playlist',
  templateUrl: './playlist.component.html',
  styleUrls: ['./playlist.component.scss']
})
export class PlaylistComponent {

  pageTitle: string = 'My Playlists';
  playlists$: Observable<Playlist[]>;
  resources$: Observable<ResourceEntry[]>;
  isPageLoading: boolean = false;
  resources: ResourceEntry[];
  streams: Stream[];

  newPlaylist: PlaylistCreation = {
    name: '',
    playlistVideos: []
  };

  constructor(
    private windowService: NbWindowService,
    private store: Store,
    private playlistService: PlaylistService,
    private toastrService: NbToastrService,
  ) {
    this.resources$ = this.store.pipe(select(getAllResources));
    this.store.pipe(select(getAllResources)).subscribe(r => this.resources = r);
    this.playlists$ = this.store.pipe(select(playlistFeature.selectPlaylistState))
  }

  ngOnInit() {
    this.store.dispatch(VideoPageActions.getVideos());
    this.store.pipe(select(streamFeature.selectStreamState)).subscribe((data) => {
      this.streams = data;
    })
    this.isPageLoading = true;
    this.playlistService.getAll().then(({data}) => {
      this.store.dispatch(PlaylistActions.fetchPlaylists({playlists: data}))
    }).finally(() => {
      this.isPageLoading = false;
    });
  }

  isPlaylistUsing(playlist: Playlist): boolean {
    const stream = this.streams.find((stream) => stream.playlist?.id === playlist.id && stream.status === StreamStatus.Online);

    if (stream && stream.playlist) {
      return stream.status === StreamStatus.Online;
    }

    return false;
  }

  openNewPlaylistModal() {
    this.windowService.open(PlaylistModalComponent, {
      title: `New Playlist`,
      buttons: buttonsConfig,
      context: {
        resources: this.resources,
        editablePlaylist: this.newPlaylist,
        onError: showWarning.bind(this, this.toastrService)
      }
    });
  }

  onPlaylistRemove(playlist: Playlist) {
    this.windowService.open(ConfirmModalComponent, {
      title: `Confirm removing playlist`,
      buttons: buttonsConfig,
      context: {
        textContent: 'All streams which use this playlist will be stopped.',
        actionCallback: () => this.playlistService.removePlaylist(playlist.id).then(({data}) => {
          this.store.dispatch(PlaylistActions.deletePlaylist({id: data.id}))
        }),
      },
    });
  }

  onPlaylistEdit(playlist: Playlist) {
    this.windowService.open(PlaylistModalComponent, {
      title: `Edit Playlist`,
      buttons: buttonsConfig,
      context: {
        resources: this.resources,
        editablePlaylist: playlist,
        onError: showWarning.bind(this, this.toastrService),
        isPlaylistUsing: this.isPlaylistUsing(playlist),
      }
    });
  }

  openPlaylistDetails(playlist: Playlist) {
    this.windowService.open(PlaylistModalComponent, {
      title: `Playlist Details`,
      buttons: buttonsConfig,
      context: {
        resources: this.resources,
        editablePlaylist: playlist,
        onError: showWarning.bind(this, this.toastrService),
        viewOnly: true
      }
    });
  }
}
