import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  NbSortDirection,
  NbSortRequest,
} from '@nebular/theme';
import { select, Store } from '@ngrx/store';
import { DataState } from 'enum/data-state.enum';
import { PageChange } from 'models/events';
import { PlaylistUploadCreationItem } from 'models/resource';
import { Observable } from 'rxjs';
import {
  getResourcePageError,
  resourceFeature
} from 'store/reducers/videos.reducers';
import { LSKeys } from 'enum/local-storage-key.enum';

@Component({
  selector: 'app-playlist-creation-video-table',
  templateUrl: './playlist-creation-video-table.component.html',
  styleUrls: ['./playlist-creation-video-table.component.scss']
})
export class PlaylistCreationVideoTableComponent implements OnInit, OnDestroy {
  @Input('resources') resources: PlaylistUploadCreationItem[];
  @Input('selectedRows') selectedRows: Set<string>;
  tableColumnLabels = {
    checkbox: 'Checkbox',
    series: 'Series',
    name: 'Video Name',
  } as const;
  tableColumnNames = Object.fromEntries(Object.entries(this.tableColumnLabels).map(([k, v]) => [v, k]));
  readonly allTableColumns = Object.keys(this.tableColumnLabels);

  sortColumn: string;
  sortRequest: NbSortRequest | null;
  sortDirection: NbSortDirection = NbSortDirection.NONE;

  error$: Observable<string | null>;
  pageState$: Observable<DataState>;
  countOfUploadingVideos: number = 0;
  vpsStatistics: { usedSpace: number, count: number, length: number } = {
    length: 0,
    usedSpace: 0,
    count: 0
  };

  pageSize: number;
  pageNumber: number = 1;
  LSPageSizeKey = LSKeys.videoPageSize;
  isSelectedAll: boolean = false;

  constructor(
    private store: Store,
  ) {
    this.pageState$ = this.store.pipe(select(resourceFeature.selectPageState));
    this.error$ = this.store.pipe(select(getResourcePageError));
    this.store.pipe(select(resourceFeature.getResourcePageStatistics)).subscribe((data: any) => {
      this.vpsStatistics = data;
    });
  }

  ngOnInit(): void {
    let pageSize = localStorage.getItem(this.LSPageSizeKey) ?? '10';
    this.pageSize = parseInt(pageSize);

    this.store.pipe(select(resourceFeature.getProcessedVideosCount))
      .subscribe((videoInUpload) => {
        this.countOfUploadingVideos = videoInUpload;
      });
  }

  updateSort(sortRequest: NbSortRequest): void {
    this.sortColumn = sortRequest.column;
    this.sortRequest = sortRequest;
    this.sortDirection = sortRequest.direction;
  }

  getSortDirection(column: string): NbSortDirection {
    if (this.sortColumn === column) {
      return this.sortDirection;
    }
    return NbSortDirection.NONE;
  }

  onPageChange(pageEvent: PageChange) {
    this.pageSize = pageEvent.pageSize;
    this.pageNumber = pageEvent.currentPage;
  }

  ngOnDestroy() {
  }

  toggleAllSelection(event: Event) {
    this.isSelectedAll = !this.isSelectedAll;
    if (this.isSelectedAll) {
      this.resources.forEach(resource => this.selectedRows.add(resource.id));
    } else {
      this.selectedRows.clear();
    }
  }

  checkIsAllShouldBeSelected() {
    this.isSelectedAll = this.resources.length === this.selectedRows.size;
  }

  toggleSelection(row: PlaylistUploadCreationItem) {
    if (this.selectedRows.has(row.id)) {
      this.selectedRows.delete(row.id);
    } else {
      this.selectedRows.add(row.id);
    }

    this.checkIsAllShouldBeSelected();
  }

  isSelected(row: PlaylistUploadCreationItem): boolean {
    return this.selectedRows.has(row.id);
  }
}
