import { Component, Input } from '@angular/core';
import { Stream } from '../../models/stream';
import { StreamPlatform, StreamStatus } from '../../enum/streams.enum';
import { VideoStatusCode } from '../../enum/video-status.code';

@Component({
  selector: 'app-stream-card',
  templateUrl: './stream-card.component.html',
  styleUrls: ['./stream-card.component.scss']
})
export class StreamCardComponent {
  @Input() editCallback: (stream: Stream) => void;
  @Input() openDetailsCallback: (stream: Stream) => void;
  @Input() startCallback: (streamId: number) => void;
  @Input() stopCallback: (streamId: number) => void;
  @Input() stream: Stream;
  @Input() isSubscriptionAllowToStart: number;
  @Input() isVpsUp: boolean;

  getPlatformName(){
    return StreamPlatform[this.stream.platformName];
  }

  getStatusColor(): string {
    return {
      0: "var(--orange-badge)",
      1: "var(--light-green)",
      2: "var(--main-orange)",
      3: "var(--button-red)",
    }[this.getStreamStatus()] || ""
  }

  getStatusText(): string {
    if (!this.isVpsUp && StreamStatus.Online === this.stream.status) {
      return StreamStatus[StreamStatus.Stopped];
    }
    return StreamStatus[this.getStreamStatus()] || "";
  }

  getStreamStatus(): number {
    if (!this.isVpsUp && StreamStatus.Online === this.stream.status) {
      return StreamStatus.Stopped;
    }
    return this.stream.status;
  }

  isPlaylistActive(): boolean {
    const playlist = this.stream.playlist;
    return !playlist?.deleted
  }

  isPlaylistNotEmpty(): boolean {
    const playlist = this.stream.playlist;
    const playlistVideos = playlist?.playlistVideos
      .map((video) => video.video)
      .filter((video) => !video.deleted) || [];
    return playlistVideos?.length > 0
  }

  onStart(e: any, streamId: number) {
    e.stopPropagation();
    this.startCallback && this.startCallback(streamId);
  }

  onStop(e: any, streamId: number) {
    e.stopPropagation();
    this.stopCallback && this.stopCallback(streamId);
  }

  getFirstVideoThumbnail() {
    const videos = this.stream.playlist?.playlistVideos
      .filter(({video}) => video.deleted === false && video.statusCode === VideoStatusCode.READY_FOR_USE) || [];
    let base64Thumbnail: string = "";
    if (videos.length > 0) {
      base64Thumbnail = videos[0].video.thumbnail || '';
    }
    return `url(${base64Thumbnail})`;
  }

  getPlaylistLength() {
    return this.stream?.playlist?.playlistVideos?.filter(({video}) => video.deleted === false && video.statusCode === VideoStatusCode.READY_FOR_USE)?.length;
  }

  activePlaylistVideos() {
    return this.stream?.playlist?.playlistVideos?.filter(({video}) => video.deleted === false)?.length || 0;
  }

  prepareTooltip(): string {
    if (!this.stream.streamKey) {
      return "Stream key should be entered for starting the stream."
    } else if (!this.stream.streamUrl) {
      return "Stream URL should be entered for starting the stream."
    } else if (!this.isPlaylistActive() || !this.isPlaylistNotEmpty()) {
      return "Playlist with at least one video should be selected for starting the stream."
    } else if (!this.isVpsUp) {
      return "Server for streaming is temporary stopped."
    } else {
      return 'Stream key and Playlist with at least one video should be selected for starting the stream.'
    }
  }

  shouldShowError(): boolean {
    return this.stream.status === 3
  }

  prepareErrorTooltip() {
    return this.stream.errorMessage ? this.stream.errorMessage.replace('\n', '\n\n') : this.stream.errorMessage;
  }

  isStreamAreNotActive() {
    return this.stream.status === StreamStatus.Offline || this.stream.status === StreamStatus.Error;
  }

  protected readonly StreamStatus = StreamStatus;
}
