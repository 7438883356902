import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { DashboardResponse } from "models/dashboard";
import { catchError, map, mergeMap, of } from "rxjs";
import { DashboardService } from "services/dashboard.service";
import { AdminDashboardPageActions } from "store/actions/dashboard.actions";

@Injectable()
export class DashboardEffects {
  getDashboardInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminDashboardPageActions.getDashboardInfo),
      mergeMap(() =>
        this.dashboardService.getDashboard$().pipe(
          map((payload: DashboardResponse[]) =>
          AdminDashboardPageActions.getDashboardInfoSuccess({ payload })
          ),
          catchError((error) =>
            of(AdminDashboardPageActions.getDashboardInfoFailure({ error: error.message }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private dashboardService: DashboardService,
  ) { }

}
