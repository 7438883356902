import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { DashboardResponse } from "models/dashboard";

@Injectable({ providedIn: 'root' })
export class DashboardService {

  private readonly apiUrl = `${environment.apiUrl}/statistic`;

  constructor(private http: HttpClient) { }

  getDashboard$(): Observable<DashboardResponse[]> {
    return this.http.get<DashboardResponse[]>(this.apiUrl);
  }

}
