import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-modal-action-buttons',
  templateUrl: './modal-action-buttons.component.html',
  styleUrls: ['./modal-action-buttons.component.scss']
})
export class ModalActionButtonsComponent {
  @Input('action-button-text') actionButtonText = "SAVE";
  @Input('close-button-text') closeButtonText = "CANCEL";
  @Input('action-button-theme') actionButtonTheme: "default" | "danger" = "default";
  @Input('action-button-loading') actionButtonLoading: boolean = false;
  @Input() actionCallback: () => void;
  @Input() cancelCallback: () => void;
  @Input() actionButtonDisabled: boolean = false;
  @Input() actionButtonBlocked: boolean = false;
}
