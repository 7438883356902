import { Component, Input, ViewChild, ViewContainerRef, OnInit, ComponentRef } from '@angular/core';
import { NbWindowRef } from '@nebular/theme';

@Component({
  selector: 'app-warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: ['./warning-modal.component.scss']
})
export class WarningModalComponent implements OnInit {
  @Input() actionCallback: () => void;
  @Input() cancelCallback: () => void;
  @Input() textContent: string = '';
  @Input() textHtml: string = '';
  @Input() actionButtonText: string = 'confirm';
  @Input() closeButtonText: string = 'close';
  @Input() actionButtonBlocked: boolean = false;
  @Input() actionButtonDisabled: boolean = false;
  @Input() customComponent: any;
  @Input() customComponentParams: any = {};
  @Input() fullWidth: boolean = false;


  @ViewChild('dynamicComponentContainer', { read: ViewContainerRef, static: true }) dynamicComponentContainer: ViewContainerRef;

  constructor(private windowRef: NbWindowRef) {}

  ngOnInit() {
    if (this.customComponent) {
      const componentRef: any = this.dynamicComponentContainer.createComponent(this.customComponent);

      if (this.customComponentParams) {
        Object.entries(this.customComponentParams).forEach(([key, value]) => {
          componentRef.instance[key] = value;
        });
      }
    }
  }

  submit() {
    this.actionCallback && this.actionCallback();
    this.windowRef.close();
  }

  closeModal() {
    this.cancelCallback && this.cancelCallback();
    this.windowRef.close();
  }
}
