import {
  Directive,
  Output,
  EventEmitter,
  HostListener,
  HostBinding
} from '@angular/core';

@Directive({
  selector: '[appUploadDnd]'
})
export class FileDndDirective {
  @HostBinding('class.fileover') fileOver: boolean;
  @Output() filesDropped = new EventEmitter<any>();

  @HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;
  }

  @HostListener('drop', ['$event']) public ondrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;
    const files: FileList | [] = event.dataTransfer?.files || [];
    this.filesDropped.emit([].slice.call(files));
  }
}
