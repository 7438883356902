<div class="new-playlist-videos">
  <label class="new-playlist-videos-label">Playlist name: {{ playlist.name }}</label>
  <div class="new-playlist-videos-checkbox">
    <nb-checkbox (change)="togglePlaylistCreation($event)" [checked]="isPlaylistShouldCreated">
      Create playlist for uploaded videos
    </nb-checkbox>
  </div>

  <app-playlist-creation-video-table
    class="new-playlist-videos-table"
    [resources]="playlist.items"
    [selectedRows]="selectedRows"
  />
</div>

<div class="mt-1 mb-1" *ngIf="selectedVideosLength() > limitUrlLinks">
    <span>
      You have entered {{selectedVideosLength()}} videos for upload, but the maximum limit of URLs is {{limitUrlLinks}}.
    </span>
    <span> Please reduce videos to start uploading</span>
</div>

<app-modal-action-buttons
  [action-button-text]="'Upload'"
  [actionCallback]="submit.bind(this)"
  [cancelCallback]="closeModal.bind(this)"
  [action-button-loading]="isLoading"
  [actionButtonDisabled]="creationDisabled()"
/>
