<div class="confirm-modal">
  <div style="overflow: hidden;">
    <p class="mb-3">{{textContent}}</p>

    <textarea
      autosize
      id="videoUrls"
      class="form-control "
      placeholder="Type your suggestion here..."
      rows="4"
      [(ngModel)]="suggestion"
      maxlength="1500"
    ></textarea>

  </div>
</div>

<app-modal-action-buttons
  action-button-text="Send Suggestion"
  action-button-theme="danger"
  [actionCallback]="submit.bind(this)"
  [cancelCallback]="closeModal.bind(this)"
  [actionButtonDisabled]="!suggestion.trim().length"
></app-modal-action-buttons>
