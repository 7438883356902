import { HttpEvent, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { UploadStatus } from 'enum/upload-status.enum';
import { getStatusFromProgress, isAuthCodeInPayload } from 'helpers/videoStatus.helper';
import { PollingPayload, ResourceEntry, ResourceResponse } from 'models/resource';
import {
  auditTime,
  catchError,
  delay,
  interval,
  map,
  mergeMap,
  of,
  pairwise,
  switchMap,
  takeUntil, tap,
  withLatestFrom
} from 'rxjs';
import { ResourceService } from 'services/resource.service';
import { VideoPageActions, VideoUploadActions } from 'store/actions/videos.actions';
import { userFeature } from 'store/reducers/user.reducers';
import { VideoSource } from '../../enum/upload-source.enum';

@Injectable()
export class VideosEffects {
  getResources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VideoPageActions.getVideos),
      mergeMap(() =>
        this.resourceService.getResources$().pipe(
          map((payload: ResourceResponse<ResourceEntry>) =>
            VideoPageActions.getVideosSuccess({ payload })
          ),
          catchError((error) =>
            of(VideoPageActions.getVideosFailure({ error: error.message }))
          )
        )
      )
    )
  );

  deleteResource$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VideoPageActions.removeVideo),
        mergeMap((action) => this.resourceService.deleteResource$(action.id))
      ),
    { dispatch: false }
  );

  deleteResources$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VideoPageActions.removeVideos),
        mergeMap((action) => this.resourceService.deleteResources$(action.ids))
      ),
    { dispatch: false }
  );


  uploadLocalFile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VideoUploadActions.uploadLocalVideo),
        withLatestFrom(this.store.select(userFeature.getUserVps)),
        mergeMap(([{ payload }, userVps]) =>
          this.resourceService.uploadFile$(userVps, payload).pipe(
            auditTime(1000),
            map(event => this.getActionFromHttpEvent(event, payload.entry.id)),
            catchError(error => of(this.handleVideoUploadError(error, payload.entry.id)))
          )
        )
      ),
  );

  uploadUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VideoUploadActions.uploadUrlVideo),
      withLatestFrom(this.store.select(userFeature.getUserVps)),
      mergeMap(([{ payload, source }, userVps]) =>
        of(undefined).pipe(
          mergeMap(() => of(VideoUploadActions.startPolling())),
          mergeMap(() => this.resourceService.uploadByUrl$(userVps, payload, source).pipe(
            map(resource => this.store.dispatch(VideoUploadActions.uploadProgressComplete({ payload: resource }))),
            catchError((error: HttpErrorResponse) =>
              of(VideoUploadActions.uploadUrlVideoFailed({error}))
            )
          )),
          map(() => VideoUploadActions.startPolling()),
        ),
      )
    )
  );

  pollProgress$ = createEffect(() => this.actions$.pipe(
    ofType(VideoUploadActions.startPolling),
    delay(2000),
    switchMap(() =>
      interval(1000).pipe(takeUntil(this.actions$.pipe(ofType(VideoUploadActions.stopPolling))))
    ),
    withLatestFrom(this.store.select(userFeature.getUserVps)),
    switchMap(([action, userVps]) => this.resourceService.fetchYouTubeProgress$(userVps)),
    pairwise(),
    map(this.getActionFromPolling),
    catchError(_ => of()),
  ));

  restartPolling$ = createEffect(() => this.actions$.pipe(
    ofType(VideoPageActions.getVideosSuccess),
    mergeMap(({payload}) => {
      const { resources } = payload;
      const hasFailedVideos = resources.some(video => getStatusFromProgress(video.uploadStatus) === UploadStatus.ERROR);
      return hasFailedVideos ? of(VideoUploadActions.startPolling()) : of(VideoUploadActions.stopPolling());
    })
  ));

  checkServiceAccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VideoUploadActions.checkServiceAccess),
      withLatestFrom(this.store.select(userFeature.getUserVps)),
      mergeMap(([{ platformName }, userVps]) =>
        of(undefined).pipe(
          mergeMap(() => this.resourceService.checkServiceAccess(userVps, platformName).pipe(
            map(() => ({ authCode: null })),
            catchError((error: HttpErrorResponse) => {
              if (error && typeof error.error === "string" && error.error.includes("Authorization required. Code: ")) {
                let code = error.error.split("Authorization required. Code: ")[1];
                return of({ authCode: code})
              }
              return of({ authCode: null });
            })
          )),
          map((data) => VideoUploadActions.authorizationRequired(data)),
        ),
      )
    )
  );

  constructor(
    private actions$: Actions,
    private resourceService: ResourceService,
    private store: Store,
  ) { }

  private getActionFromHttpEvent(event: HttpEvent<any>, id: number) {
    switch (event.type) {
      case HttpEventType.Sent: {
        return VideoUploadActions.uploadProgressUpdate({ payload: { [id.toString()]: {uploadStatus: 0} } });
      }
      case HttpEventType.UploadProgress: {
        let uploadStatus = Math.round(100 * event.loaded / event.total!);
        return VideoUploadActions.uploadProgressUpdate({ payload: { [id.toString()]: {uploadStatus} } });
      }
      case HttpEventType.Response: {
        if (event.status === 200) {
          let payload: ResourceEntry = event.body as ResourceEntry;
          return VideoUploadActions.uploadProgressComplete({ payload });
        } else {
          return VideoUploadActions.uploadProgressFailure({ id });
        }
      }
      default: {
        return VideoUploadActions.uploadProgressFailure({ id });
      }
    }
  }

  private getActionFromPolling(data: PollingPayload[]) {
    const [previousPayload, currentPayload] = data;

    if (Object.keys(currentPayload).length === 0) return VideoUploadActions.stopPolling();

    if (isAuthCodeInPayload(previousPayload) && !isAuthCodeInPayload(currentPayload)) {
      return VideoUploadActions.authorizationRequired({authCode: null});
    }

    return VideoUploadActions.uploadProgressUpdate({payload: currentPayload});
  }

  private handleVideoUploadError(error: any, id: number) {
    console.error(error);
    return VideoUploadActions.uploadProgressFailure({id});
  }
}
