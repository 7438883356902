import { Injectable } from '@angular/core';
import axios, { AxiosResponse } from 'axios';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Character, ChatMessage, DraftCharacter } from '../models/character';


@Injectable({
  providedIn: 'root'
})
export class CharacterService {

  private readonly apiUrl = environment.apiUrl + '/character';

  constructor(private http: HttpClient) {
  }

  getAll(userUuid: string): Promise<AxiosResponse<Character[]>> {
    return axios.get(`${this.apiUrl}/user/${userUuid}`)
  }


  getAll$(userUuid: string | undefined): Observable<Character[]> {
    return this.http.get<Character[]>(`${this.apiUrl}/user/${userUuid}`)
  }

  createNewCharacter(newCharacterData: DraftCharacter) {
    return axios.post(`${this.apiUrl}/`, newCharacterData)
  }

  updateCharacter(updatedCharacterData: Character) {
    return axios.put(`${this.apiUrl}/`, updatedCharacterData);
  }

  async deleteCharacter(characterId: string): Promise<AxiosResponse<Character>> {
    const res = await axios.delete(`${this.apiUrl}/${characterId}`);
    return res.data;
  }

  deleteCharacters(ids: string[]): Observable<boolean> {
    return this.http.delete<boolean>(`${this.apiUrl}/list`, { body: { ids }} );
  }

  sendMessage$(messages: ChatMessage[], characterId: string, resourceId: number | undefined): Observable<any> {
    const queryParams = resourceId ? `?resourceId=${resourceId}` : '';
    return this.http.post<any>(`${this.apiUrl}/${characterId}/messages${queryParams}`, messages)
  }
}
