import { Injectable } from '@angular/core';
import axios, { AxiosResponse } from 'axios';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AiModel } from '../models/ai-model';


@Injectable({
  providedIn: 'root'
})
export class AiModelService {

  private readonly apiUrl = environment.apiUrl + '/models';

  constructor(private http: HttpClient) {
  }

  getAll(): Promise<AxiosResponse<AiModel[]>> {
    return axios.get(this.apiUrl)
  }

  getAll$(): Observable<AiModel[]> {
    return this.http.get<AiModel[]>(this.apiUrl)
  }
}
