import { Component, Input } from '@angular/core';
import { Playlist } from 'models/playlist';
import { VideoStatusCode } from '../../enum/video-status.code';

@Component({
  selector: 'app-playlist-card',
  templateUrl: './playlist-card.component.html',
  styleUrls: ['./playlist-card.component.scss']
})
export class PlaylistCardComponent {
  @Input() editCallback: (playlist: Playlist) => void;
  @Input() openDetailsCallback: (playlist: Playlist) => void;
  @Input() removeCallback: (playlist: Playlist) => void;
  @Input() isPlaylistUsing: boolean = false;
  @Input() playlist: Playlist;

  onRemove(e: any) {
    e.stopPropagation();
    this.removeCallback && this.removeCallback(this.playlist);
  }

  isPlaylistNotEmpty(): boolean {
    const playlistVideos = this.playlist?.playlistVideos
      .map((video) => video.video)
      .filter((video) => !video.deleted || video.statusCode !== VideoStatusCode.READY_FOR_USE) || [];
    return playlistVideos?.length > 0
  }

  getFirstVideoThumbnail(): string {
    const videos = this.playlist?.playlistVideos
      .filter(({video}) => video.deleted === false && video.statusCode === VideoStatusCode.READY_FOR_USE) || [];
    let base64Thumbnail: string = '';
    if (videos.length > 0) {
      base64Thumbnail = videos[0].video.thumbnail || '';
    }
    return base64Thumbnail;

  }

  activePlaylistVideos() {
    return this.playlist?.playlistVideos?.filter(({video}) => video.deleted === false && video.statusCode === VideoStatusCode.READY_FOR_USE)?.length || 0;
  }
}
