export const allowedEmails = ['admin@juststream.pro', 'support@juststream.pro', 'customer@juststream.pro',
  'oleksandrk@softwareplanetgroup.com',
  'juststream004@gmail.com', 'juststream006@gmail.com', 'juststream002@gmail.com', 'jspersonal82@gmail.com',
  'jspersonal82.2@gmail.com', 'motionartphotography@gmail.com', 'romanpr@softwareplanetgroup.com'
];

export const allowedStreamPlatforms = [
  'Custom',
  'YouTube',
  'Twitch',
  'Facebook',
  'Kick',
  'Rumble',
];
