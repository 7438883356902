import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { DashboardConfigChange } from 'models/events';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private readonly apiUrl = `${environment.apiUrl}/config`;

  constructor(private http: HttpClient) { }

  updateConfig$(payload: DashboardConfigChange) {
    return this.http.put(this.apiUrl, payload);
  }

}
