<app-card>
<div class="vps-modal-container">
  <table>
    <tr>
      <th>Id</th>
      <th>IP Address</th>
      <th>Software Version</th>
      <th>Assigned to</th>
      <th>Subscription</th>
      <th>Videos</th>
      <th>Playlists</th>
      <th>Streams</th>
    </tr>
    <tr>
      <td>{{vpsDetails.id}}</td>
      <td>{{vpsDetails.vpsIp}}</td>
      <td>{{vpsDetails.softwareVersion}}</td>
      <td>{{vpsDetails.assignedTo}}</td>
      <td>{{vpsDetails.subscriptionPlan}}</td>
      <td>
        <ng-container>
          <div *ngFor="let videoSummary of vpsDetails.videoSummaries">
            <div class="subtitle-2">{{videoSummary.userEmail}}</div>
            <div>{{videoSummary.mp4Number}} mp4 - {{videoSummary.mp4Size | number}} GB</div>
            <div>{{videoSummary.flvNumber}} flv - {{videoSummary.flvSize | number}} GB</div>
          </div>
          <div>
            {{vpsDetails.spaceState}}
          </div>
        </ng-container>
      </td>
      <td>
        <ng-container>
          <div *ngFor="let playlist of vpsDetails.playlists">
            <div class="subtitle-2">{{playlist.name}}</div>
            <div>{{playlist.videoNumber}} vids</div>
            <div>{{playlist.videoLength | videoLength}}</div>
          </div>
        </ng-container>
      </td>
      <td>
        <ng-container>
          <div *ngFor="let stream of vpsDetails.streams">
            <div class="subtitle-2">{{getPlatformName(stream.platformId)}}</div>
            <div>{{stream.playlistName}}</div>
            <div>Start Date: {{stream.startedAt * 1000 | date: 'hh:mm:ss yyyy/MM/dd'}}</div>
          </div>
        </ng-container>
      </td>
    </tr>
  </table>
</div>
</app-card>
