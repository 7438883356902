import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'videoLength'
})
export class VideoLengthPipe implements PipeTransform {

  transform(seconds: number): string {
    if (seconds === 0) {
      return "0s";
    } else if (seconds < 60) {
      return '< 1m';
    } else {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const hoursString = hours > 0 ? `${hours}h ` : '';
      const minutesString = minutes > 0 ? `${minutes}m` : '';
      return `${hoursString} ${minutesString}`;
    }
  }
}
