<div>
  <div style="max-height: 600px; overflow: scroll; overflow-x: hidden;">
    <table
      nbSort
      class="my-videos-table"
      (sort)="updateSort($event)"
      [nbTreeGrid]="(resources$ | async | videoDefaultSort | paginate: pageSize:pageNumber | transformTableGrid | sortNbTable: sortRequest ) || []"
    >
      <tr nbTreeGridHeaderRow *nbTreeGridHeaderRowDef="allTableColumns"></tr>
      <tr nbTreeGridRow *nbTreeGridRowDef="let row; columns: allTableColumns" [clickToToggle]="false"></tr>

      <ng-container [nbTreeGridColumnDef]="tableColumnNames[tableColumnLabels.checkbox]">
        <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef class="p-0">
          <div class="my-videos-table-checkbox">
            <nb-checkbox
              (change)="toggleAllSelection($event)"
              [checked]="isSelectedAll"
              class="my-videos-table-checkbox"
            />
          </div>
        </th>
        <td nbTreeGridCell *nbTreeGridCellDef="let row" class="p-0">
          <div class="my-videos-table-checkbox">
            <nb-checkbox type="checkbox" [checked]="isSelected(row.data)" (change)="toggleSelection(row.data)"/>
          </div>
        </td>
      </ng-container>

      <ng-container [nbTreeGridColumnDef]="tableColumnNames[tableColumnLabels.series]">
        <th
          nbTreeGridHeaderCell
          *nbTreeGridHeaderCellDef
        ></th>
        <td
          class="my-videos-table-peripheral p-0"
          nbTreeGridCell
          *nbTreeGridCellDef="let i = index"
        >
          {{ (pageSize * (pageNumber - 1)) + (i + 1) }}
        </td>
      </ng-container>

      <ng-container [nbTreeGridColumnDef]="tableColumnNames[tableColumnLabels.name]">
        <th
          style="width: 40%;"
          nbTreeGridHeaderCell
          [nbSortHeader]="getSortDirection(tableColumnLabels.name)"
          *nbTreeGridHeaderCellDef
        >
          {{ tableColumnLabels.name }}
        </th>
        <td
          nbTreeGridCell
          *nbTreeGridCellDef="let row"
        >
          <div class="table-thumbnail" [ngClass]="{'table-thumbnail-vertical': row.data.isVerticalOrientation}">
            <img [id]="row?.data?.id" [src]="row.data.thumbnail || 'assets/DefaultVideoThumbnail.jpg'" alt="Thumbnail">
          </div>
          <span class="my-videos-table-video-name">{{ row.data.name }}</span>
        </td>
      </ng-container>

      <ng-container [nbTreeGridColumnDef]="tableColumnNames[tableColumnLabels.length]">
        <th
          style="width: 15%;"
          nbTreeGridHeaderCell
          [nbSortHeader]="getSortDirection(tableColumnLabels.length)"
          *nbTreeGridHeaderCellDef
        >
          {{ tableColumnLabels.length }}
        </th>
        <td
          nbTreeGridCell
          *nbTreeGridCellDef="let row;"
        >
          {{ row.data.length | videoLength }}
        </td>
      </ng-container>

      <ng-container [nbTreeGridColumnDef]="tableColumnNames[tableColumnLabels.uploadStatus]">
        <th
          style="width: 20%;"
          nbTreeGridHeaderCell
          [nbSortHeader]="getSortDirection(tableColumnLabels.uploadStatus)"
          *nbTreeGridHeaderCellDef
        >
          {{ tableColumnLabels.uploadStatus }}
        </th>
        <td
          nbTreeGridCell
          *nbTreeGridCellDef="let row"
        >
          <app-video-status [id]="row.data.id" (openCheckAccessModal)="openCheckAccessModal()"/>
        </td>
      </ng-container>

      <ng-container [nbTreeGridColumnDef]="tableColumnNames[tableColumnLabels.size]">
        <th
          style="width: 15%;"
          nbTreeGridHeaderCell
          [nbSortHeader]="getSortDirection(tableColumnLabels.size)"
          *nbTreeGridHeaderCellDef
        >
          {{ tableColumnLabels.size }}
        </th>
        <td
          nbTreeGridCell
          *nbTreeGridCellDef="let row;"
        >
          {{ row.data.size | videoSize }}
        </td>
      </ng-container>

      <ng-container [nbTreeGridColumnDef]="tableColumnNames[tableColumnLabels.actions]">
        <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef ></th>
        <td
          class="my-videos-table-peripheral"
          nbTreeGridCell
          *nbTreeGridCellDef="let row"
        >
          <div class="d-flex justify-content-center align-items-center">
            <button
              *ngIf="!isUserInAllowedEmails()"
              nbButton
              ghost
              (click)="deleteResource(row.data)"
            >
              <nb-icon icon="trash-2-outline"/>
            </button>
            <nb-action
              *ngIf="isUserInAllowedEmails()"
              icon="more-vertical"
              (click)='contextMenuActiveRow = row.data'
              nbContextMenuTrigger="click"
              [nbContextMenu]="getVideoTableContextItems(row.data)"
              [nbContextMenuTag]='contextMenuTag'
              [nbContextMenuPlacement]="contextItemsPosition"
            />
          </div>
        </td>
      </ng-container>
    </table>
  </div>
  <ng-container *ngIf="resources$ | async as resources">
    <app-card-pagination
      [collectionSize]="resources.length"
      [LSKey]="LSPageSizeKey"
      (onPageChange)="onPageChange($event)"
    />
  </ng-container>
</div>
