import { UploadStatus } from 'enum/upload-status.enum';
import { PollingPayload } from '../models';

export function getStatusFromProgress(code: number) {
  if (code === -1) {
    return UploadStatus.ERROR;
  } else if (code === 0) {
    return UploadStatus.PROCESSING;
  } else if (0 < code && code < 99) {
    return UploadStatus.UPLOADING;
  } else {
    return UploadStatus.READY;
  }
}

export function isAuthCodeInPayload(payload: PollingPayload) {
  return Object.values(payload).some((e: any) => e.authCode);
}
