import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { DashboardResponse } from "models/dashboard";

export const AdminDashboardPageActions = createActionGroup({
  source: 'Admin Dashboard Page',
  events: {
    'Get Dashboard Info': emptyProps(),
    'Get Dashboard Info success': props<{ payload: DashboardResponse[] }>(),
    'Get Dashboard Info failure': props<{ error: string }>(),
  }
});
