<div class="playlist-modal">
  <!-- NAME -->
  <div class="playlist-modal-name">
    <label class="playlist-modal-name-label">Playlist Name</label>
    <nb-form-field>
      <input
        nbInput
        fullWidth
        type="text"
        maxlength="255"
        class="playlist-name-input"
        placeholder="Enter Playlist Name"
        fieldSize="medium"
        [value]="editablePlaylist.name"
        (change)="onChangeName($event.target)"
        [disabled]="viewOnly"
      >
    </nb-form-field>
  </div>
  <!-- TABLE -->
  <div class="playlist-modal-attached">
    <ng-container *ngIf="editablePlaylist.playlistVideos.length === 0">
      <span>You don't have a videos in your playlist</span>
    </ng-container>
    <ng-container *ngIf="editablePlaylist.playlistVideos.length > 0">

      <div class="playlist-modal-attached-action">
        <span>Video</span>
        <button *ngIf="!viewOnly" nbButton (click)="shuffleVideos()">
          <nb-icon icon="shuffle-outline"></nb-icon>
          Shuffle
        </button>
      </div>

      <div class="playlist-modal-attached-wrapper">

        <table class="playlist-table custom table table-bordered text-center mb-0" cdkDropList
              (cdkDropListDropped)="onDrop($event)">
          <thead>
          <tr>
            <th scope="col" class="playlist-th" style="width: 10%;"></th>
            <th scope="col" class="playlist-th" style="width: 60%">Name</th>
            <th scope="col" class="playlist-th" style="width: 20%;">Length</th>
            <th scope="col" class="playlist-th" style="width: 10%;"></th>
          </tr>
          </thead>
          <tbody>
          <tr cdkDrag cdkDragLockAxis="y" cdkDragHandle *ngFor="let video of editablePlaylist.playlistVideos">
            <td class="playlist-td">
              <img src="assets/DndDots.svg" class="playlist-table-dnd-icon">
              {{video.order + 1}}
            </td>
            <td class="playlist-td">
              <div class="table-thumbnail">
                <img [id]="video.video.id" [src]="video.video.thumbnail || 'assets/DefaultVideoThumbnail.jpg'" alt="Thumbnail">
              </div>
              <span class="video-name">{{ video.video.name }}</span>
            </td>
            <td class="playlist-td" >{{(video.video.length || 0) | videoLength}}</td>
            <td class="playlist-td">
              <div class="d-flex justify-content-center align-items-center">
                <button *ngIf="!viewOnly" (click)="deleteResourceFromPlaylist(video.video.id || 0)">
                  <nb-icon
                    icon="trash-outline"
                    status="danger"
                  ></nb-icon>
                </button>
              </div>
            </td>

            <!-- Preview -->
            <div *cdkDragPreview matchSize="true" class="container dragdrop-placeholder">
              {{video.video.name}}
            </div>
          </tr>
          </tbody>
        </table>

        <table class="playlist-table attached-video-summary custom table table-bordered text-center mb-0">
          <tbody>
            <tr>
              <td class="playlist-td" style="width: 70%;">
                <span>Total:</span>
                <div class="table-thumbnail" style="visibility: hidden;">
                  <img [src]="'assets/DefaultVideoThumbnail.jpg'" alt="Thumbnail">
                </div>
              </td>
              <td class="playlist-td" style="width: 30%;">
                <p>{{ editablePlaylist | playlistVideoLength }}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
  </div>
  <!-- VIDEOS LIST -->
  <ng-container *ngIf="!viewOnly">
    <div class="playlist-modal-available-videos">
      <div class="video-search">
        <label class="video-search-label">Select multiple videos for your playlist</label>
        <nb-form-field style="align-items: flex-start;">
          <nb-icon nbPrefix icon="search" pack="eva"/>
          <input
            nbInput
            fullWidth
            type="text"
            placeholder="Search Video"
            class="video-search-input"
            fieldSize="medium"
            (keyup)="onSearch($event.target)"
          >
        </nb-form-field>
      </div>
      <div class="video-list">
        <nb-tabset (changeTab)="onTabChange($event)">
          <nb-tab tabTitle="Horizontal" class="my-videos-tab">
          </nb-tab>
          <nb-tab tabTitle="Vertical" class="my-videos-tab">
          </nb-tab>
        </nb-tabset>
        <app-video-list-table
          *ngIf="getNotAddedVideos(resources).length"
          [searchableResources]="searchableResources"
          [editablePlaylist]="editablePlaylist"
          [getNotAddedVideos]="getNotAddedVideos.bind(this)"
          [addVideoToPlaylist]="addVideoToPlaylist.bind(this)"
        />
      </div>
    </div>
  </ng-container>
</div>

<app-modal-action-buttons
  [actionCallback]="submit.bind(this)"
  [cancelCallback]="closeModal.bind(this)"
  [actionButtonDisabled]="viewOnly"
></app-modal-action-buttons>
