import { Pipe, PipeTransform } from '@angular/core';
import { NbTreeGridDataSourceBuilder } from '@nebular/theme';

@Pipe({
  name: 'transformTableGrid'
})
export class TransformTableGridPipe implements PipeTransform {

  constructor(private dataSourceBuilder: NbTreeGridDataSourceBuilder<any>) {}

  transform(dataToDisplay: any[] | null) {
    if (!dataToDisplay) return null;

    return this.dataSourceBuilder.create(this.reformatData(dataToDisplay))
  }

  private reformatData(resources: any[]) {
    return resources.map((resource: any) => ({ data: resource }))
  }

}
