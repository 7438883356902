import { Injectable } from '@angular/core';
import axios, { AxiosResponse } from 'axios';
import { environment } from '../../environments/environment';
import { DraftVps, Vps } from '../models/vps';
import { User } from '../models/user';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { VpsDetailsDto } from '../models/vpsDetailsDto';


@Injectable({
  providedIn: 'root'
})
export class VpsService {

  private readonly apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  getAll(): Promise<AxiosResponse<Vps[]>> {
    return axios.get(`${this.apiUrl}/vps`);
  }

  getVpsDetails(vpsId: number) : Promise<AxiosResponse<VpsDetailsDto>> {
    return axios.get(`${this.apiUrl}/vps/${vpsId}`);
  }

  getAll$(): Observable<Vps[]> {
    return this.http.get<Vps[]>(`${this.apiUrl}/vps`);
  }

  createNewVps(newVpsData: DraftVps) {
    return axios.post(`${this.apiUrl}/vps`, newVpsData);
  }

  updateVps(updatedVpsData: DraftVps) {
    return axios.put(`${this.apiUrl}/vps`, updatedVpsData);
  }

  unassignUserFromVps(vpsId: string, userEmail: string): Promise<AxiosResponse<Vps>> {
    return axios.post(`${this.apiUrl}/vps/unassign`, {id: vpsId, userEmail: userEmail});
  }

  updateVpsSoftware(vpsId: string): Promise<AxiosResponse<Vps>> {
    return axios.post(`${this.apiUrl}/vps/update`, {id: vpsId}, {timeout: 1000 * 60 * 2});
  }

  getContaboAllVps(): Promise<AxiosResponse<object[]>> {
    return axios.get(`${this.apiUrl}/vps/all`);
  }

  getAllVpsStatuses(): Promise<AxiosResponse<{ ip: string, status: number; }[]>> {
    return axios.get(`${this.apiUrl}/vps/all/status`);
  }

  getVpsForCurrentUser(): Promise<Vps> {
    return axios.get(`${this.apiUrl}/vps/user/current`).then(res => res.data);
  }

  checkVpsConnection(vpsPayload: DraftVps): Promise<boolean> {
    return axios.post(`${this.apiUrl}/vps/check/connection`, vpsPayload).then(res => res.data);
  }

  deleteVps(vpsId: string): Promise<AxiosResponse<Vps>> {
    return axios.delete(`${this.apiUrl}/vps/${vpsId}`).then(res => res.data);
  }

  getVpsLogs(vpsId: string): Promise<AxiosResponse<Vps>> {
    return axios.get(`${this.apiUrl}/vps/${vpsId}/logs`).then(res => res.data);
  }
}
