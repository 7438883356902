<div class="character-form">
  <form [formGroup]="characterForm" (ngSubmit)="submit()">
    <div class="form-group">
      <label for="name">Name of the Character*</label>
      <input
        id="name"
        placeholder="type name of character"
        formControlName="name"
        type="text"
        class="form-control character-form-input"
        maxlength="250"
      >
      <div class="character-form-input-token-count">Tokens: {{ tokenCounts.name }}</div>
    </div>

    <div class="form-group">
      <label for="description">Description of the Character</label>
      <textarea
        id="description"
        placeholder="type description of character"
        formControlName="description"
        class="form-control character-form-input"
        maxlength="1500"
      ></textarea>
      <div class="character-form-input-token-count">Tokens: {{ tokenCounts.description }}</div>
    </div>

    <div class="form-group">
      <label for="personality">Personality of the Character</label>
      <textarea
        id="personality"
        placeholder="type personality of character"
        formControlName="personality"
        class="form-control character-form-input"
        maxlength="1500"
      ></textarea>
      <div class="character-form-input-token-count">Tokens: {{ tokenCounts.personality }}</div>
    </div>

    <div class="form-group">
      <label for="firstMes">Greeting Message</label>
      <input
        id="firstMes"
        placeholder="type greeting message"
        formControlName="firstMes"
        type="text"
        class="form-control character-form-input"
        maxlength="200"
      >
      <div class="character-form-input-token-count">Tokens: {{ tokenCounts.firstMes }}</div>
    </div>

    <div formArrayName="questionAnswers" class="form-group character-form-input-question-answers">
      <label>Question-Answers</label>
      <div
        *ngFor="let qa of questionAnswers.controls; let i = index"
        [formGroupName]="i"
        class="qa-pair character-form-input-question-answers-block"
      >
        <input formControlName="question" placeholder="Question" class="form-control">
        <textarea
          formControlName="answer"
          placeholder="Answer"
          class="form-control character-form-input-question-answers-block-answer"
          rows="1"
          (input)="autoResize($event)"
        ></textarea>
        <button nbButton status="danger" type="button" (click)="removeQuestionAnswer(i)">Remove</button>
      </div>
      <div class="character-form-input-token-count">
        <button nbButton status="primary" type="button" (click)="addQuestionAnswer()">Add Question-Answer</button>
        <div>
          Tokens: {{ tokenCounts.questionAnswers }}
        </div>
      </div>

    </div>

    <div class="form-group character-form-input-models">
      <label for="model">LLM Model</label>
      <app-search-select [options$]="models$" id="model" formControlName="model" [placeholder]="'Select model'"/>
      <div class="character-form-input-token-count">
        Tokens: {{ tokenCounts.model }}
      </div>
    </div>

    <div class="form-group character-form-input-temperature">
      <label for="temperature">Temperature</label>
      <ngx-slider id="temperature" formControlName="temperature" [(value)]="characterForm.value.temperature" [options]="getOptions('temperature')"/>
    </div>

    <div class="expert-mode">
      <nb-checkbox [checked]="isExpertMode" (change)="toggleExpertMode()" >For expert users</nb-checkbox>
    </div>
    <div *ngIf="isExpertMode" class="expert-mode-params">
      <div *ngFor="let param of getExpertPramsLabels()" class="form-group">
        <label [for]="param">{{ replaceName(param) }}</label>
        <ngx-slider [id]="param" [formControlName]="param" [(value)]="characterForm.value[param]" [options]="getOptions(param)"/>
      </div>
    </div>

    <app-modal-action-buttons
      [action-button-text]="'Save'"
      [actionCallback]="submit.bind(this)"
      [cancelCallback]="closeModal.bind(this)"
      [action-button-loading]="isLoading"
    />
  </form>
</div>
