import { Component, Input } from '@angular/core';
import { NbWindowRef } from '@nebular/theme';

@Component({
  selector: 'app-edit-video-modal',
  templateUrl: './edit-video-modal.component.html',
  styleUrls: ['./edit-video-modal.component.scss']
})
export class EditVideoModalComponent {

  @Input() actionCallback: (data: string) => void;
  @Input() videoName: string = '';

  constructor(private windowRef: NbWindowRef) {
  }

  saveNewConfig() {
    this.actionCallback && this.actionCallback(this.videoName)
    this.windowRef.close();
  }

  closeModal() {
    this.windowRef.close();
  }
}
