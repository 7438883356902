import { createFeature, createReducer, createSelector, on } from "@ngrx/store";
import { User } from "models/user";
import { UserActions } from "store/actions/user.actions";

export type UserState = User | {};

const initialUserState: UserState = {};

export const userFeature = createFeature({
  name: 'user',
  reducer: createReducer(
    initialUserState,
    on(UserActions.setUser, (state, { user }) => ({...state, ...user })),
    on(UserActions.updateUser, (state, { user }) => ({ ...user, ...state })),
    on(UserActions.saveUserVps, ((state, address) => ({ vpsAddress: address, ...state }))),
    on(UserActions.clearUser, (state) => ({}))),
  extraSelectors: ({ selectUserState }) => ({
    getUserVps: createSelector(
      selectUserState,
      (user: User) => Object.keys(user).length === 0 ? undefined : user.assignedVps
    )
  })
});
