import { Component, Input, ViewChild, forwardRef, ElementRef, AfterViewInit } from '@angular/core';
import { from, map, Observable, tap, take } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectOption } from '../../models';

@Component({
  selector: 'app-search-select',
  template: `
    <input
      class="search-select-input"
      #autoInput
      nbInput
      type="text"
      (input)="onChange()"
      (blur)="onBlur()"
      (focus)="onFocus()"
      [placeholder]="customPlaceholder || placeholder"
      [nbAutocomplete]="auto"
    />
    <nb-autocomplete #auto (selectedChange)="onSelectionChange($event)">
      <nb-option
        *ngFor="let option of (filteredOptions$ | async) as options"
        [value]="getOptionValue(option)"
        (mouseenter)="onOptionMouseEnter()"
        (mouseleave)="onOptionMouseLeave()"
      >
        {{ getOptionName(option) }}
      </nb-option>
    </nb-autocomplete>
  `,
  styleUrls: ['./search-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchSelectComponent),
      multi: true
    }
  ]
})
export class SearchSelectComponent implements ControlValueAccessor, AfterViewInit {
  @Input('options$') options$: Observable<string[] | SelectOption[]>;
  @Input('placeholder') placeholder: string = '';
  filteredOptions$: Observable<(string | SelectOption)[]>;
  @ViewChild('autoInput') input: ElementRef<HTMLInputElement>;
  initialValue: string;
  customPlaceholder: string;
  preventBlurAction: boolean = false;

  private onChangeCallback: (_: any) => void = () => {};
  private onTouchedCallback: () => void = () => {};
  ngAfterViewInit() {
    this.filteredOptions$ = from(this.options$).pipe(
      tap(data => {
        if (this.initialValue) {
          this.writeValue(this.initialValue);
        } else {
          this.input.nativeElement.value = this.getOptionName(data[0]);
          this.onChange();
        }
      })
    );
  }

  getFilteredOptions(value: string): Observable<(string | SelectOption)[]> {
    return this.options$.pipe(
      map((options: any) => options.filter((option: any) => this.getOptionName(option).toLowerCase().includes(value.toLowerCase()))),
    );
  }

  onChange() {
    const value = this.input.nativeElement.value;
    this.filteredOptions$ = this.getFilteredOptions(value);
    this.options$.subscribe((options: any) => {
      const selectedOption = options.find((option: any) => this.getOptionName(option) === value);
      if (selectedOption) {
        this.onChangeCallback(this.getOptionValue(selectedOption));
      } else {
        this.onChangeCallback(null);
      }
    });
  }

  onSelectionChange($event: string) {
    this.options$.pipe(take(1)).subscribe((options: any) => {
      const selectedOption = options.find((option: any) => this.getOptionValue(option) === $event);
      if (selectedOption) {
        this.input.nativeElement.value = this.getOptionName(selectedOption);
        this.input.nativeElement.blur();
        this.onChangeCallback(this.getOptionValue(selectedOption));

      }
    });
  }

  onBlur() {
    if (this.preventBlurAction) {
      return;
    }
    const value = this.input.nativeElement.value;
    this.options$.pipe(take(1)).subscribe((options: any) => {
      const selectedOption = options.find((option: any) => this.getOptionName(option) === value);
      if (selectedOption) {
        this.onChangeCallback(this.getOptionValue(selectedOption));
        return;
      }

      if (this.initialValue) {
        const initialOption = options.find((option: any) => this.getOptionValue(option) === this.initialValue);
        this.input.nativeElement.value = this.getOptionName(initialOption);
      } else {
        this.input.nativeElement.value = '';
        this.onChangeCallback(null);
      }
    });
  }

  onFocus() {
    this.customPlaceholder = this.input.nativeElement.value;
    this.input.nativeElement.value = '';
    this.filteredOptions$ = this.options$
  }

  writeValue(value: any): void {
    if (this.input && value !== undefined) {
      this.options$.pipe(take(1)).subscribe((options: any) => {
        const selectedOption = options.find((option: any) => this.getOptionValue(option) === value);
        if (selectedOption) {
          this.input.nativeElement.value = this.getOptionName(selectedOption);
          this.filteredOptions$ = this.getFilteredOptions(this.getOptionName(selectedOption));
        } else {
          this.input.nativeElement.value = '';
        }
      });
    } else {
      this.initialValue = value;
    }
  }

  onOptionMouseEnter() {
    this.preventBlurAction = true;
  }

  onOptionMouseLeave() {
    this.preventBlurAction = false;
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (this.input) {
      this.input.nativeElement.disabled = isDisabled;
    }
  }

  protected getOptionValue(option: string | SelectOption): string {
    return typeof option === 'string' ? option : option.value;
  }

  protected getOptionName(option: string | SelectOption): string {
    return typeof option === 'string' ? option : option.name;
  }
}
