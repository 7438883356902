<div class="playlist-card card">
  <div
    class="playlist-card-info d-flex justify-content-between align-items-center"
    [style]="{color: isPlaylistNotEmpty() ? 'white' : 'inherit'}"
  >
    <span *ngIf='playlist' class="unselectable">
      {{activePlaylistVideos()}} Videos
      <span *ngIf='activePlaylistVideos() > 0'>({{ playlist | playlistVideoLength }})</span>
    </span>

    <div *ngIf="isPlaylistUsing">
      <nb-tag
        appearance="filled"
        status="primary"
        class="unselectable"
        [text]="'Streaming'"
        [ngStyle]="{
        backgroundColor: 'var(--light-green)',
        border: 'none'
      }"
      ></nb-tag>
    </div>
  </div>

  <div class="playlist-card-title">
    <h1 [style]="{color: isPlaylistNotEmpty() ? 'white' : 'inherit'}">{{ playlist.name }}</h1>
  </div>

  <div class="playlist-card-action">
    <button nbButton (click)="editCallback(playlist)" *ngIf="!isPlaylistUsing">
      <nb-icon icon="edit-2-outline"></nb-icon>
      Edit
    </button>
    <button nbButton (click)="openDetailsCallback(playlist)" *ngIf="isPlaylistUsing">
      <nb-icon icon="settings-outline"></nb-icon>
      Details
    </button>
    <button nbButton status="danger" (click)="onRemove($event)" *ngIf="!isPlaylistUsing">
      <nb-icon icon="trash-outline"></nb-icon>
      Remove
    </button>
  </div>

  <img
    *ngIf="isPlaylistNotEmpty()"
    [src]="isPlaylistNotEmpty() ? getFirstVideoThumbnail() : 'none'"
    class="background-image"
    alt="Video Thumbnail"
  >
</div>
