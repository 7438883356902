import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { userFeature } from '../../store/reducers/user.reducers';
import { User } from '../../models/user';
import { allowedEmails } from '../../consts';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  user: User;

  constructor(private route: ActivatedRoute, protected router: Router, private store: Store,) {
    this.updateSidebarItems();
  }

  updateSidebarItems() {
    this.store.pipe(select(userFeature.selectUserState)).subscribe((user: any) => this.user = user)
  }

  logout() {
    // this.store.dispatch(UserActions.clearUser())
    // localStorage.removeItem('user');
    // localStorage.removeItem('sessionToken');
    document.location.href = environment.homePage;
  }

  openLandingApp() {
    document.location.href = environment.homePage;
  }

  openContactForm() {
    window.open(`${environment.homePage}/contact`, '_blank');
  }

  isAllowedToUse() {
    return !environment.production || this.user && allowedEmails.includes(this.user?.email || ''); // TODO: delete after allow feature to all users
  }
}
