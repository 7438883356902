<div
  class="stream-card card"
  [style]="{ backgroundImage: isPlaylistNotEmpty() ? getFirstVideoThumbnail() : 'url(\'assets/DefaultStreamThumbnail.jpg\')'}"
>
  <div class="stream-card-foreground-container"></div>
  <div class="stream-card-content">
    <div class="stream-card-info d-flex justify-content-between flex-row mb-2">
      <h5 class="text-white">{{ getPlatformName() }}</h5>
      <div>
        <nb-tag
          appearance="filled"
          status="primary"
          class="unselectable"
          [text]="getStatusText()"
          [ngStyle]="{
        backgroundColor: getStatusColor(),
        border: 'none'
      }"
        ></nb-tag>
      </div>
    </div>
    <div class="stream-card-title text-white d-flex flex-column">
      <div class="multiline-ellipsis">
        <h6 *ngIf="!stream.playlist || !isPlaylistActive()" class="text-white mb-0">No playlist selected</h6>
        <h6 *ngIf="stream.playlist && isPlaylistActive()" class="text-white mb-0">{{stream.playlist.name}}</h6>
      </div>
      <div *ngIf="stream.playlist && isPlaylistActive() && activePlaylistVideos() > 0">
        {{getPlaylistLength()}}
        Videos
        {{isPlaylistNotEmpty() ? "(" + (stream.playlist | playlistVideoLength) + ")" : ""}}
      </div>
    </div>
    <div class="stream-card-action">
      <div class="stream-card-action-content">
        <button
          nbButton
          *ngIf="isStreamAreNotActive()"
          (click)="editCallback(stream)"
        >
          <nb-icon icon="edit-2-outline"/>
          Edit
        </button>
        <button nbButton *ngIf="stream.status === StreamStatus.Online" (click)="openDetailsCallback(stream)">
          <nb-icon icon="settings-outline"></nb-icon>
          Details
        </button>
        <button nbButton *ngIf="(isStreamAreNotActive() && stream.id) || (!isVpsUp && stream.id)"
                status="success"
                class="play-button"
                [disabled]="!(stream.streamKey && stream.streamUrl) || !isPlaylistActive() || !isPlaylistNotEmpty() || !isVpsUp || stream.status === StreamStatus.Error"
                (click)="onStart($event, stream.id)">
          <nb-icon icon="play-circle"></nb-icon>
          Start
        </button>
        <button nbButton *ngIf="stream.status === StreamStatus.Online && stream.id && isVpsUp" status="danger" (click)="onStop($event, stream.id)">
          <nb-icon icon="stop-circle"></nb-icon>
          Stop
        </button>
      </div>
      <div class="stream-card-action-content">
        <svg xmlns="http://www.w3.org/2000/svg"
             *ngIf="!(stream.streamKey && stream.streamUrl) || !isPlaylistActive() || !isPlaylistNotEmpty() || !isVpsUp"
             [ngbTooltip]="prepareTooltip()"
             width="28" height="28" fill="#00d68f" viewBox="0 0 256 256">
          <path d="M224,128a96,96,0,1,1-96-96A96,96,0,0,1,224,128Z" opacity="0.2"></path>
          <path d="M140,180a12,12,0,1,1-12-12A12,12,0,0,1,140,180ZM128,72c-22.06,0-40,16.15-40,36v4a8,8,0,0,0,16,0v-4c0-11,10.77-20,24-20s24,9,24,20-10.77,20-24,20a8,8,0,0,0-8,8v8a8,8,0,0,0,16,0v-.72c18.24-3.35,32-17.9,32-35.28C168,88.15,150.06,72,128,72Zm104,56A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z"></path>
        </svg>
        <nb-icon
          *ngIf="shouldShowError()"
          icon="alert-circle-outline"
          status="danger"
          [ngbTooltip]="prepareErrorTooltip()"
          class="stream-card-error"
        />
      </div>
    </div>
  </div>
</div>
