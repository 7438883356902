<div [ngClass]="['dashboard-card', 'card', dashboardCard.relate]">
  <div class="dashboard-card-heading"><h6>{{ title }}</h6></div>
  <div class="dashboard-card-details">
    <ng-container
      *ngIf="dashboardCard.type === 'text'; then plainText; else selectOption">
    </ng-container>
  </div>
</div>

<ng-template #plainText>
  <span>{{ dashboardCard.data }}</span>
</ng-template>

<ng-template #selectOption>
  <nb-select
    [selected]="optionValue"
    (selectedChange)="changeConfig($event)"
  >
    <nb-option [value]="1">1</nb-option>
    <nb-option [value]="2">2</nb-option>
    <nb-option [value]="3">3</nb-option>
    <nb-option [value]="4">4</nb-option>
    <nb-option [value]="5">5</nb-option>
    <nb-option [value]="10">10</nb-option>
    <nb-option [value]="15">15</nb-option>
  </nb-select>
</ng-template>
