import { Pipe, PipeTransform } from '@angular/core';
import { NbTreeGridDataSource } from '@nebular/theme';

@Pipe({
  name: 'filterNbTable'
})
export class FilterNbTablePipe implements PipeTransform {

  transform(tableData: NbTreeGridDataSource<any> | null, filter: string = "") {
    if (tableData) tableData.filter(filter);
    return tableData;
  }

}
