import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const sessionToken = localStorage.getItem('sessionToken') || undefined;
    const supportMode = sessionStorage.getItem('supportMode') || undefined;
    const supportUserId = sessionStorage.getItem('supportUserId') || undefined;

    let authReq = sessionToken ? req.clone({
      headers: req.headers
        .append('Authorization', sessionToken)
    }) : req;

    if (supportMode && supportUserId) {
      authReq = authReq.clone({
        headers: authReq.headers
        .append('X-Support-Mode-Enabled', supportMode)
        .append('X-Support-UserId', supportUserId)
      })
    }

    // send cloned request with header to the next handler.
    return next.handle(authReq);
  }
}
