import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from '../../environments/environment';
import { Playlist, PlaylistCreation } from '../models/playlist';
import { PlaylistActions } from '../store/actions/playlist.actions';
import { Store } from '@ngrx/store';


@Injectable({
  providedIn: 'root'
})
export class PlaylistService {

  private readonly apiUrl = environment.apiUrl;

  constructor(private store: Store) {
  }

  getAll() {
    return axios.get(`${this.apiUrl}/playlist`)
  }

  removePlaylist(playlistId: number) {
    return axios.delete(`${this.apiUrl}/playlist/${playlistId}`)
  }

  createPlaylist(playlist: PlaylistCreation) {
    return axios.post(`${this.apiUrl}/playlist`, playlist).then(({data}) => {
      this.store.dispatch(PlaylistActions.addPlaylist({playlist: data}))
    }).catch(console.log)
  }

  updatePlaylist(playlist: Playlist) {
    return axios.put(`${this.apiUrl}/playlist`, playlist).then(({data}) => {
      this.store.dispatch(PlaylistActions.updatePlaylist({playlist: data}))
    }).catch(console.log)
  }

  saveOrUpdatePlaylist(editablePlaylist: PlaylistCreation | Playlist) {
    return editablePlaylist && editablePlaylist?.id ?
      this.updatePlaylist(editablePlaylist as Playlist) :
      this.createPlaylist(editablePlaylist as PlaylistCreation)
  }
}
