import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterData'
})
export class FilterDataPipe implements PipeTransform {

  transform(data: Array<any> | [], filter: string = "", keysToFilter: string[]) {
    let isHasFilterColumns = keysToFilter && keysToFilter.length > 0;
    if (data) {
      return data.filter((entry) => {
        return this.getFilteredObjectIfRequired(isHasFilterColumns, keysToFilter, entry)
          .reduce((isContainProperty, value: any) => {
          const searchResult = value ? value.toString().includes(filter) : false;

          return searchResult ? searchResult : isContainProperty;
        }, false)
      });
    }

    return data;
  }

  getFilteredObjectIfRequired(isHasFilterColumns: boolean, keysToFilter: any[], entry: any) {
    if (isHasFilterColumns) {
      return Object.keys(entry)
       .filter(key => keysToFilter.includes(key))
       .map(key => entry[key]);
    }

    return Object.values(entry)
  }
}
