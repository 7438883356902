import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  NbMenuItem, NbMenuService, NbPosition,
  NbSortDirection,
  NbSortRequest,
  NbToastrService, NbWindowRef,
  NbWindowService
} from '@nebular/theme';
import { select, Store } from '@ngrx/store';
import { ConfirmModalComponent } from 'components/confirm-modal/confirm-modal.component';
import { DataState } from 'enum/data-state.enum';
import { PageChange } from 'models/events';
import { ResourceEntry } from 'models/resource';
import {
  filter, map,
  Observable, Subscription,
  take
} from 'rxjs';
import { VideoPageActions } from 'store/actions/videos.actions';
import {
  getAllResources,
  getResourcePageError,
  resourceFeature
} from 'store/reducers/videos.reducers';
import { Stream } from 'models/stream';
import { streamFeature } from 'store/reducers/stream.reducers';
import { StreamStatus } from 'enum/streams.enum';
import { PlaylistVideo } from 'models/playlist';
import { buttonsConfig, Character, User } from '../../models';
import { showWarning } from 'helpers/nb';
import { LSKeys } from 'enum/local-storage-key.enum';
import { VideoCharacterModalComponent } from '../video-character-modal/video-character-modal.component';
import { ResourceService } from '../../services/resource.service';
import { allowedEmails } from '../../consts';
import { ChatModalComponent } from '../chat-modal/chat-modal.component';
import { characterFeature } from '../../store/reducers/characters.reducers';

@Component({
  selector: 'app-video-table',
  templateUrl: './video-table.component.html',
  styleUrls: ['./video-table.component.scss']
})
export class VideoTableComponent implements OnInit, OnDestroy {
  @Input('resources$') resources$: Observable<ResourceEntry[]>;
  @Input('selectedRows') selectedRows: Set<number>;
  @Input('user') user: User;
  tableColumnLabels = {
    checkbox: 'Checkbox',
    series: 'Series',
    name: 'Video Name',
    length: 'Length',
    uploadStatus: 'Video Status',
    size: 'Used Space',
    actions: 'Actions',
  } as const;
  tableColumnNames = Object.fromEntries(Object.entries(this.tableColumnLabels).map(([k, v]) => [v, k]));
  readonly allTableColumns = Object.keys(this.tableColumnLabels);

  sortColumn: string;
  sortRequest: NbSortRequest | null;
  sortDirection: NbSortDirection = NbSortDirection.NONE;

  error$: Observable<string | null>;
  pageState$: Observable<DataState>;
  countOfUploadingVideos: number = 0;
  vpsStatistics: { usedSpace: number, count: number, length: number } = {
    length: 0,
    usedSpace: 0,
    count: 0
  };
  streams$: Observable<Stream[]>;
  characters$: Observable<Character[]>;
  pageSize: number;
  pageNumber: number = 1;
  LSPageSizeKey = LSKeys.videoPageSize;
  isSelectedAll: boolean = false;

  videoTableContextItems: NbMenuItem[] = [
    {title: 'Delete', data: this.deleteResource.bind(this)}
  ];
  videoWithoutCharacterTableContextItems: NbMenuItem[] = [
    {title: 'Assign Character', data: this.editCharacter.bind(this)},
  ];
  videoWithCharacterTableContextItems: NbMenuItem[] = [
    {title: 'Edit Character', data: this.editCharacter.bind(this)},
    {title: 'Test Character', data: this.testCharacter.bind(this)},
    {title: 'Unassign Character', data: this.unassignCharacter.bind(this)},
  ];
  contextItemsPosition: NbPosition = NbPosition.BOTTOM;
  contextMenuTag: string = 'my-video-table-context-menu';
  contextMenuActiveRow: any;
  menuServiceSubscription: Subscription;
  subscription: Subscription = new Subscription();
  newVideoModalRef: NbWindowRef<any, any>;

  constructor(
    private windowService: NbWindowService,
    private store: Store,
    private toastrService: NbToastrService,
    private nbMenuService: NbMenuService,
    private resourceService: ResourceService,
  ) {
    this.pageState$ = this.store.pipe(select(resourceFeature.selectPageState));
    this.error$ = this.store.pipe(select(getResourcePageError));
    this.characters$ = this.store.pipe(select(characterFeature.getAllCharacters));
    this.store.pipe(select(resourceFeature.getResourcePageStatistics)).subscribe((data: any) => {
      this.vpsStatistics = data;
    });
    this.streams$ = this.store.pipe(select(streamFeature.selectStreamState));
    this.menuServiceSubscription = this.nbMenuService.onItemClick()
      .pipe(
        filter(({tag}) => tag === this.contextMenuTag),
        map(({item: {data}}) => data),
      )
      .subscribe(callback => {
        if (callback) callback(this.contextMenuActiveRow);
      });
  }

  ngOnInit(): void {
    let pageSize = localStorage.getItem(this.LSPageSizeKey) ?? '10';
    this.pageSize = parseInt(pageSize);

    this.subscription.add(this.store.pipe(select(resourceFeature.getProcessedVideosCount))
      .subscribe((videoInUpload) => {
        this.countOfUploadingVideos = videoInUpload;
      })
    );
    this.subscription.add(this.resources$.subscribe(() => this.isSelectedAll = false));
  }

  deleteResource(resource: ResourceEntry) {
    this.streams$.pipe(take(1)).subscribe((allStreams) => {
      const videoIsUsed: boolean = this.isVideoUsedByStreams(resource, allStreams);
      if (videoIsUsed) {
        showWarning(this.toastrService, 'To delete this video, you must first stop the stream that is currently using it. The video cannot be removed while it is still being utilized by the stream.')
        return;
      }

      this.windowService.open(ConfirmModalComponent, {
        title: `Are You Sure You Want to Delete this Video?`,
        buttons: buttonsConfig,
        context: {
          textContent: `This action will permanently delete the video "${resource.name}" and it cannot be undone.`,
          actionCallback: () => this.store.dispatch(VideoPageActions.removeVideo({id: resource.id})),
        },
      });
    })
  }

  editCharacter(resource: ResourceEntry) {
    const isResourceHasCharacter = this.isResourceHasCharacter(resource);
    this.newVideoModalRef = this.windowService.open(VideoCharacterModalComponent, {
      title: `${isResourceHasCharacter ? 'Edit' : 'Assign'} video character`,
      buttons: buttonsConfig,
      closeOnEsc: false,
      context: {
        user: this.user,
        resource: resource,
        onSubmit: this.onCreateModalSubmit.bind(this),
        onError: showWarning.bind(this, this.toastrService),
      },
      windowClass: "scroll-modal"
    });
  }

  testCharacter(resource: ResourceEntry) {
    const resourceCharacter = resource.resourceCharacter;
    if (!resourceCharacter) {
      return;
    }
    this.characters$.pipe(take(1)).subscribe(characters => {
      this.windowService.open(ChatModalComponent, {
        title: `Test character chat`,
        buttons: buttonsConfig,
        closeOnEsc: false,
        context: {
          user: this.user,
          character: characters.find(({ id }) => id === resourceCharacter.characterId),
          resource: resource,
          onSubmit: () => {},
          onError: showWarning.bind(this, this.toastrService),
        },
        windowClass: "scroll-modal"
      });
    })

  }


  unassignCharacter(resource: ResourceEntry) {
    this.windowService.open(ConfirmModalComponent, {
      title: `Are You Sure You Want to Delete this Character?`,
      buttons: buttonsConfig,
      context: {
        textContent: `This action will unassign character from the video.`,
        actionCallback: () => this.onUnassignCharacter(resource),
      },
    });
  }

  onUnassignCharacter(resource: ResourceEntry) {
    this.resourceService.unassignCharacter(resource.id)
      .pipe(
        take(1),
        map((payload: any) => ({
            ...payload,
            resourceCharacter: null,
        }))
      )
      .subscribe((payload: ResourceEntry) => {
        this.store.dispatch(VideoPageActions.updateVideo({payload}))
      });
  }

  onCreateModalSubmit(data: any) {
    const { videoId, ...resourceCharacter } = data;
    if (resourceCharacter.id) {
      this.resourceService.editCharacter(resourceCharacter, videoId)
        .pipe(take(1))
        .subscribe((payload: any) => {
          this.store.dispatch(VideoPageActions.updateVideo({payload}))
        });
    } else {
      this.resourceService.assignCharacter(resourceCharacter, videoId)
        .pipe(take(1))
        .subscribe((payload: any) => {
          this.store.dispatch(VideoPageActions.updateVideo({payload}))
        });
    }
  }

  updateSort(sortRequest: NbSortRequest): void {
    this.sortColumn = sortRequest.column;
    this.sortRequest = sortRequest;
    this.sortDirection = sortRequest.direction;
  }

  getSortDirection(column: string): NbSortDirection {
    if (this.sortColumn === column) {
      return this.sortDirection;
    }
    return NbSortDirection.NONE;
  }

  onPageChange(pageEvent: PageChange) {
    this.pageSize = pageEvent.pageSize;
    this.pageNumber = pageEvent.currentPage;
  }

  ngOnDestroy() {
    this.menuServiceSubscription.unsubscribe();
    this.subscription.unsubscribe();
  }

  private isVideoUsedByStreams(video: ResourceEntry, allStreams: Stream[]) {
    const playlistVideos: PlaylistVideo[] = allStreams
      .filter(stream => stream.status === StreamStatus.Online)
      .map((activeStream: Stream) => activeStream.playlist?.playlistVideos || [])
      .flat();

    return playlistVideos.some(item => item.video.id === video.id);
  }

  toggleAllSelection(event: Event) {
    this.resources$.pipe(take(1)).subscribe((resources) => {
      this.isSelectedAll = !this.isSelectedAll;
      if (this.isSelectedAll) {
        resources.forEach(resource => this.selectedRows.add(resource.id));
      } else {
        this.selectedRows.clear();
      }
    });
  }

  checkIsAllShouldBeSelected() {
    this.resources$.pipe(take(1)).subscribe((resources) => {
      this.isSelectedAll = !!resources.length && resources.length === this.selectedRows.size;
    });
  }

  toggleSelection(row: ResourceEntry) {
    if (this.selectedRows.has(row.id)) {
      this.selectedRows.delete(row.id);
    } else {
      this.selectedRows.add(row.id);
    }

    this.checkIsAllShouldBeSelected();
  }

  isSelected(row: ResourceEntry): boolean {
    return this.selectedRows.has(row.id);
  }

  isResourceHasCharacter(resource: ResourceEntry) {
    return resource && !!resource.resourceCharacter;
  }

  getVideoTableContextItems(row: ResourceEntry) {
    if (this.isResourceHasCharacter(row)) {
      return [
        ...this.videoWithCharacterTableContextItems,
        ...this.videoTableContextItems,
      ];
    }
    return [
      ...this.videoWithoutCharacterTableContextItems,
      ...this.videoTableContextItems,
    ];
  }

  isUserInAllowedEmails() {
    return this.user && allowedEmails.includes(this.user?.email || ''); // TODO: delete after allow feature to all users
  }
}
