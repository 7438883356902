<app-top-bar page-title='VPS Management' [labels]='[[vpsData.length, "Image ID"]]'/>

<app-card>
  <div class='d-flex justify-content-between vps-action-header'>
    <button
      nbButton
      status="primary"
      class="vps-action-header-button"
      (click)='openNewVpsModal()'
    >Add VPS</button>
    <nb-form-field style="align-items: flex-start;">
      <nb-icon nbPrefix icon="search" pack="eva"/>
      <input
        nbInput
        id='search'
        type="text"
        class='search-input mb-0'
        placeholder='Search'
        fieldSize="medium"
        [(ngModel)]="filterString"
        >
    </nb-form-field>
  </div>

  <table
    nbSort
    equalColumnsWidth
    (sort)='changeSort($event)'
    [nbTreeGrid]="(vpsData | filterData: filterString:keysToFilter | paginate: pageSize:pageNumber | transformTableGrid | sortNbTable: sortRequest ) || []"
  >

    <tr nbTreeGridHeaderRow *nbTreeGridHeaderRowDef='allTableColumns'></tr>
    <tr nbTreeGridRow *nbTreeGridRowDef='let row; columns: allTableColumns' [clickToToggle]='false'></tr>

    <ng-container [nbTreeGridColumnDef]="tableColumnNames[tableColumnLabels.id]">
      <th
        nbTreeGridHeaderCell
        [nbSortHeader]="getDirection(tableColumnLabels.id)"
        *nbTreeGridHeaderCellDef
      >{{ tableColumnLabels.id }}</th>
      <td nbTreeGridCell *nbTreeGridCellDef='let row'>{{ row.data['id'] }}</td>
    </ng-container>

    <ng-container [nbTreeGridColumnDef]="tableColumnNames[tableColumnLabels.sv]">
      <th
        style="width: 15%;"
        nbTreeGridHeaderCell
        [nbSortHeader]="getDirection(tableColumnLabels.sv)"
        *nbTreeGridHeaderCellDef
      >{{ tableColumnLabels.sv }}</th>
      <td nbTreeGridCell *nbTreeGridCellDef='let row'>{{ row.data.softwareVersion }}</td>
    </ng-container>

    <ng-container [nbTreeGridColumnDef]="tableColumnNames[tableColumnLabels.ip]">
      <th
        style="width: 20%;"
        nbTreeGridHeaderCell
        [nbSortHeader]="getDirection(tableColumnLabels.ip)"
        *nbTreeGridHeaderCellDef
      >{{ tableColumnLabels.ip }}</th>
      <td nbTreeGridCell *nbTreeGridCellDef='let row'>
        <span
          class="{{isActiveVpsStatus(row.data) ? 'vps-status-ok' : 'vps-status-err'}}"
          [ngbTooltip]="getStatusText(row.data)"
        >{{ row.data.ip }}</span>
      </td>
    </ng-container>

    <ng-container [nbTreeGridColumnDef]="tableColumnNames[tableColumnLabels.userEmail]">
      <th
        style="width: 40%;"
        nbTreeGridHeaderCell
        [nbSortHeader]="getDirection(tableColumnLabels.userEmail)"
        *nbTreeGridHeaderCellDef
      >{{ tableColumnLabels.userEmail }}</th>
      <td nbTreeGridCell *nbTreeGridCellDef='let row'>{{ row.data.userEmail }}</td>
    </ng-container>

    <ng-container [nbTreeGridColumnDef]="tableColumnNames[tableColumnLabels.paymentStatus]">
      <th
        style="width: 15%;"
        nbTreeGridHeaderCell
        [nbSortHeader]="getDirection(tableColumnLabels.paymentStatus)"
        *nbTreeGridHeaderCellDef
      >{{ tableColumnLabels.paymentStatus }}</th>
      <td nbTreeGridCell *nbTreeGridCellDef='let row'>{{ row.data.subscription }}</td>
    </ng-container>

    <ng-container [nbTreeGridColumnDef]="tableColumnNames[tableColumnLabels.plan]">
      <th
        style="width: 15%;"
        nbTreeGridHeaderCell
        [nbSortHeader]="getDirection(tableColumnLabels.plan)"
        *nbTreeGridHeaderCellDef
      >{{ tableColumnLabels.plan }}</th>
      <td nbTreeGridCell *nbTreeGridCellDef='let row'>{{ row.data.plan }}</td>
    </ng-container>

    <ng-container [nbTreeGridColumnDef]="tableColumnNames[tableColumnLabels.actions]">
      <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef style='width: 5%'></th>

      <td nbTreeGridCell *nbTreeGridCellDef='let row'>
        <div class="d-flex justify-content-center align-items-center">
          <nb-action
            icon="more-vertical"
            nbContextMenuTrigger="click"
            (click)='contextMenuActiveRow = row.data'
            [nbContextMenu]="row.data.userEmail ? nbMenuItems : nbMenuItemsWithoutUnAssign"
            [nbContextMenuTag]='contextMenuTag'
          />
        </div>
      </td>
    </ng-container>

  </table>

  <app-card-pagination
    [collectionSize]="vpsData.length"
    [LSKey]="LSPageSizeKey"
    [currentPage]="pageNumber"
    (onPageChange)="onPageChange($event)"
  >
  </app-card-pagination>
</app-card>

<div class='mt-4'>
  <app-contabo-vps-table [usedVps]='vpsData' [onStartUsingVps]='onStartUsingVps.bind(this)'></app-contabo-vps-table>
</div>

