import { Component, Input } from '@angular/core';
import { NbTabComponent, NbWindowRef } from '@nebular/theme';
import { ResourceEntry } from 'models/resource';
import { Playlist, PlaylistCreation, PlaylistVideo } from '../../models/playlist';
import { PlaylistService } from '../../services/playlist.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { select, Store } from '@ngrx/store';
import { playlistFeature } from '../../store/reducers/playlist.reducers';
import { VideoStatusCode } from '../../enum/video-status.code';

@Component({
  selector: 'app-playlist-modal',
  templateUrl: './playlist-modal.component.html',
  styleUrls: ['./playlist-modal.component.scss']
})
export class PlaylistModalComponent {

  @Input() editablePlaylist: Playlist | PlaylistCreation;
  onError: (message: string) => void;
  viewOnly = false;

  resources: ResourceEntry[];
  searchableResources: ResourceEntry[] = [];
  playlists: Playlist[] = [];
  initialPlaylistName: string;
  tabTitle: string = 'Horizontal';

  constructor(
    private windowRef: NbWindowRef,
    private playlistService: PlaylistService,
    private store: Store,
  ) {
    this.store.pipe(select(playlistFeature.selectPlaylistState))
      .subscribe((data: any) => this.playlists = data);
  }

  ngOnInit() {
    this.searchableResources = this.getAllResources();
    this.initialPlaylistName = this.editablePlaylist?.name || '';
  }

  getAllResources() {
    return this.resources.filter((res) => res.statusCode === VideoStatusCode.READY_FOR_USE);
  }

  onChangeName({value}: any) {
    this.editablePlaylist = {...this.editablePlaylist, name: value.trim()};
  }

  submit() {
    if (this.editablePlaylist.name.trim() === '') {
      this.onError('The playlist name should not be blank');
      return;
    } else if (!this.isNameUniq()) {
      this.onError('Playlist with same name already exist.');
      return;
    }

    this.playlistService.saveOrUpdatePlaylist(this.editablePlaylist).then(() => {
      this.windowRef.close();
    })
  }

  isNameUniq() {
    if (this.initialPlaylistName === this.editablePlaylist.name) {
      return true;
    }

    const playlistWithSameName = this.playlists
      .find((ps) => ps.name === this.editablePlaylist.name.trim() && this.editablePlaylist.id !== ps.id);
    return !playlistWithSameName;
  }

  deleteResourceFromPlaylist(videoId: number) {
    this.editablePlaylist = {
      ...this.editablePlaylist,
      playlistVideos: [...this.editablePlaylist.playlistVideos.filter((playlistVideo) => playlistVideo.video.id !== videoId)]
    };
  }

  closeModal() {
    this.windowRef.close();
  }

  shuffleVideos() {
    const reorderedPlaylist = this.editablePlaylist.playlistVideos
      .map(value => ({value, sort: Math.random()}))
      .sort((a, b) => a.sort - b.sort)
      .map(({value}, index) => ({...value, order: index}));

    this.editablePlaylist = {...this.editablePlaylist, playlistVideos: reorderedPlaylist};
  }

  addVideoToPlaylist(orderNumber: number, data: ResourceEntry) {
    this.editablePlaylist = {
      ...this.editablePlaylist,
      playlistVideos: [...this.editablePlaylist.playlistVideos, {order: orderNumber, video: data}]
    }
  }

  onSearch({value}: any) {
    if (!value) {
      this.searchableResources = this.getAllResources();
    }

    return this.searchableResources = this.getAllResources().filter(res => {
      const resourceNameLower = res.name.toLowerCase();
      const searchedTextLower = value.toLowerCase();
      return resourceNameLower.indexOf(searchedTextLower) > -1;
    })
  }

  getNotAddedVideos = (resources: ResourceEntry[]) => {
    const addedVideoIds = this.editablePlaylist.playlistVideos.map(({video}) => video.id);
    return resources.filter((resource) => !addedVideoIds.includes(resource.id)
      && (this.tabTitle === 'Horizontal' ? !resource.isVerticalOrientation : resource.isVerticalOrientation)
    );
  }

  onDrop(event: CdkDragDrop<string[]>) {
    const playlistVideosForReordering = [...this.editablePlaylist.playlistVideos];
    moveItemInArray(playlistVideosForReordering, event.previousIndex, event.currentIndex);
    const playlistVideos = playlistVideosForReordering
      .map(({video}: PlaylistVideo, index: number): PlaylistVideo => ({order: index, video}))

    this.editablePlaylist = {...this.editablePlaylist, playlistVideos};
  }

  onTabChange(tab: NbTabComponent) {
    this.tabTitle = tab.tabTitle;
  }

  protected readonly console = console;
}
