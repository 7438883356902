import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { NbToastrService, NbWindowRef } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { getAuthorizationCode } from '../../store/reducers/videos.reducers';
import { StreamPlatform } from '../../enum/streams.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { StreamService } from '../../services/stream.service';

@Component({
  selector: 'app-vps-logs',
  templateUrl: `./vps-logs.component.html`,
  styleUrls: ['./vps-logs.component.scss']
})
export class VpsLogsComponent {
  @Input() logs: string;

  ngAfterViewInit() {
    const logsContainer = document.getElementById('logs');
    if (logsContainer) {
      logsContainer.scrollTop = logsContainer.scrollHeight;
    }
  }
}

