import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  FileCreation,
  PlaylistUploadCreation,
  PollingPayload,
  ResourceEntry,
  ResourceResponse,
  ResourceUploadPayload
} from 'models/resource';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import axios from 'axios';
import { splitFileName } from 'helpers/resource.helper';
import { Vps } from 'models/vps';
import { UploadVideoSource } from '../enum/upload-source.enum';
import { ResourceCharacter } from '../models';

@Injectable({ providedIn: 'root' })
export class ResourceService {

  private readonly apiUrl = `${environment.apiUrl}/resource`;

  constructor(private http: HttpClient) { }

  getResources$(): Observable<ResourceResponse<ResourceEntry>> {
    return this.http.get<ResourceResponse<ResourceEntry>>(`${this.apiUrl}/get-all`);
  }

  createEntities(source: UploadVideoSource, fileCreation: FileCreation[]): Promise<ResourceEntry[]> {
    return axios.post(`${this.apiUrl}/${source}`, fileCreation).then(res => res.data);
  }

  updateEntity(updatedResource: ResourceEntry): Promise<ResourceEntry> {
    return axios.patch<ResourceEntry>(this.apiUrl, updatedResource).then(res => res.data);
  }

  deleteResource$(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.apiUrl}/delete/${id}`);
  }

  deleteResources$(ids: number[]): Observable<boolean> {
    return this.http.delete<boolean>(`${this.apiUrl}/delete`, { body: { ids }} );
  }

  deleteAll$(userEmail: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.apiUrl}/delete/all/${userEmail}`);
  }

  // Client VPS

  readonly defaultProxyConfig = (ipAddress: string) => ({
    headers: {"X-VPS-IP": ipAddress}
  });

  uploadByUrl$(vps: Vps | undefined, { entry, sourceId }: ResourceUploadPayload, source: UploadVideoSource): Observable<any> {
    if (!vps) {
      throw new Error("Could not download YouTube video");
    }
    const payload = { sourceId, id: entry.id, name: entry.physicalName };
    return this.http.post(`${environment.host}/proxy/upload/${source}`, payload, this.defaultProxyConfig(vps.ip));
  }

  uploadFile$(vps: Vps | undefined, { entry, file }: ResourceUploadPayload): Observable<HttpEvent<ResourceEntry>> {
    if (!vps) {
      throw new Error("Could not upload local file");
    }
    const formData: FormData = new FormData();
    const [_, extension] = splitFileName(file!);
    const physicalNameWithExt = `${entry.physicalName}.${extension}`;
    formData.append('file', file!, physicalNameWithExt);
    formData.append('id', entry.id.toString());

    return this.http.post<ResourceEntry>(`${environment.host}/proxy/upload/local`, formData, {
      ...this.defaultProxyConfig(vps.ip),
      reportProgress: true,
      observe: 'events',
    });
  }

  fetchYouTubeProgress$(vps: Vps | undefined) {
    if (!vps) {
      throw new Error("Could not get YouTube upload progress!");
    }
    return this.http.get<PollingPayload>(`${environment.host}/proxy/upload/progress`, this.defaultProxyConfig(vps.ip));
  }

  assignCharacter(resourceCharacter: ResourceCharacter, videoId: number) {
    return this.http.post<boolean>(`${this.apiUrl}/${videoId}/character`, resourceCharacter);
  }

  editCharacter(resourceCharacter: ResourceCharacter, videoId: number) {
    return this.http.patch<boolean>(`${this.apiUrl}/${videoId}/character`, resourceCharacter);
  }

  unassignCharacter(videoId: number) {
    return this.http.delete<boolean>(`${this.apiUrl}/${videoId}/character`);
  }

  checkServiceAccess(vps: Vps | undefined, service: string) {
    if (!vps) {
      throw new Error("Could not get YouTube upload access!");
    }
    return this.http.get<string>(`${environment.host}/proxy/access/${service}`, this.defaultProxyConfig(vps.ip));
  }

  getPlaylistVideos(source: UploadVideoSource, playlistId: string): Promise<PlaylistUploadCreation> {
    return axios.get(`${this.apiUrl}/${source}/playlist?playlistId=` + playlistId).then(res => res.data);
  }
}
