import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { User } from 'models/user';
import { Vps } from 'models/vps';

export const UserActions = createActionGroup({
  source: 'User',
  events: {
    'Set User': props<{ user: User }>(),
    'Update User': props<{ user: User }>(),
    'Save User VPS': props<{ userVps: Vps }>(),
    'Clear User': emptyProps(),
  }
});
