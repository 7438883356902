<div class="edit-modal">
    <label for="new-video-name">New Video Name</label>
    <input
      id="new-video-name"
      nbInput
      type="text"
      maxlength="255"
      [(ngModel)]="videoName"
    >
</div>

<app-modal-action-buttons
  [actionCallback]="saveNewConfig.bind(this)"
  [cancelCallback]="closeModal.bind(this)"
></app-modal-action-buttons>
