import { Component } from '@angular/core';
import { NbWindowRef } from '@nebular/theme';
import { Stream, StreamCreation } from '../../models/stream';
import { Playlist } from '../../models/playlist';
import { Store } from '@ngrx/store';
import { StreamService } from '../../services/stream.service';
import { StreamActions } from '../../store/actions/stream.actions';
import { PlatformStreamUrl, StreamPlatform } from 'enum/streams.enum';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { environment } from '../../../environments/environment';
import { allowedStreamPlatforms } from '../../consts';

@Component({
  selector: 'app-new-stream-modal',
  templateUrl: './new-stream-modal.component.html',
  styleUrls: ['./new-stream-modal.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0,
        height: '0',
        width: '0'
      })),
      transition('void <=> *', animate('500ms ease-in-out'))
    ])
  ]
})
export class NewStreamModalComponent {

  // Modal props
  editableStream: StreamCreation | Stream;
  playlists: Playlist[] = [];
  streams: Stream[] = [];
  onError: (message: string) => void;
  viewOnly = false;

  showStreamingKey: boolean = false;
  readonly StreamPlatform = StreamPlatform;

  constructor(
    private windowRef: NbWindowRef,
    private streamService: StreamService,
    private store: Store,
  ) {
  }

  updateField({value}: any, fieldName: string) {
    this.editableStream = {...this.editableStream, [fieldName]: value}
  }

  submit() {
    this.editableStream = {
      ...this.editableStream,
      playlist: this.playlists.find((pl) => pl.id == this.editableStream?.playlistId)
    }
    const currentStreamId = this.editableStream.id;
    let isDuplicatedKey = false;

    if (currentStreamId) {
      isDuplicatedKey = this.streams
        .filter(stream => (stream.id !== currentStreamId && stream.streamKey))
        .some(stream => stream.streamKey === this.editableStream.streamKey);
    } else {
      isDuplicatedKey= this.streams
        .filter(stream => stream.streamKey)
        .some(stream => stream.streamKey === this.editableStream.streamKey);
    }

    if (isDuplicatedKey) {
      this.onError("This streaming key already exist!");
      return;
    }

    this.streamService.createOrUpdateStream(this.editableStream).then(({data}) => {
      if (currentStreamId) {
        this.store.dispatch(StreamActions.updateStream({stream: data}))
      } else {
        this.store.dispatch(StreamActions.addStream({stream: data}))
      }
    })
    this.windowRef.close();
  }

  onPlatformChange(value: number) {
    const streamingUrl = PlatformStreamUrl[value]
    this.updateField({value: streamingUrl}, "streamUrl")
  }

  closeModal() {
    this.windowRef.close();
  }

  selectComparator(v1: any, v2: any) {
    return v1 == v2
  }

  isVertical(playlist: Playlist) {
    if (!!playlist?.playlistVideos?.length
      && !playlist.playlistVideos.some(({video}) => !video.isVerticalOrientation)
    ) {
      return " - VERTICAL";
    } else {
      return "";
    }
  }

  protected readonly environment = environment;
  protected readonly allowedStreamPlatforms = allowedStreamPlatforms;
}
