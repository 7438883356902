<div [classList]=' vpsData &&  vpsData.length ? "" : "d-none"'>
<!--  <span nbButton (click)='toggle()' class='mb-3 mt-3'>{{item.collapsed ? "Expand" : "Collapse"}} VPS Pool</span>-->

  <nb-accordion>
    <nb-accordion-item #item>
      <nb-accordion-item-body>
        <div>
          <table [nbTreeGrid]='dataSource' nbSort (sort)='changeSort($event)' equalColumnsWidth>

            <tr nbTreeGridHeaderRow *nbTreeGridHeaderRowDef='allColumns'></tr>
            <tr nbTreeGridRow *nbTreeGridRowDef='let row; columns: allColumns' [clickToToggle]='false'></tr>

            <ng-container *ngFor='let column of defaultColumns' [nbTreeGridColumnDef]='column'>
              <th nbTreeGridHeaderCell [nbSortHeader]='getDirection(column)' *nbTreeGridHeaderCellDef>
                {{labels[column]}}
              </th>

              <td nbTreeGridCell *nbTreeGridCellDef='let row'>{{row.data[column]}}</td>
            </ng-container>

            <ng-container [nbTreeGridColumnDef]='customColumn'>
              <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef style='width: 15%'></th>

              <td nbTreeGridCell *nbTreeGridCellDef='let row'>
                <span class='d-flex justify-content-center'>
                  <button (click)='onStartUsing(row.data["ip"])' type='button' class='btn btn-success btn-sm' *ngIf='!row.data["isUsed"]'>
                    Start Using
                  </button>
                  <span *ngIf='row.data["isUsed"]'>Using!</span>
                </span>
              </td>
            </ng-container>
          </table>
        </div>
      </nb-accordion-item-body>
    </nb-accordion-item>

  </nb-accordion>
</div>
