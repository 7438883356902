import { Component, OnInit } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Store, select } from '@ngrx/store';
import { DataState } from 'enum/data-state.enum';
import { DashboardSummary } from 'models/dashboard';
import { DashboardConfigChange } from 'models/events';
import { Observable } from 'rxjs';
import { ConfigService } from 'services/config.service';
import { AdminDashboardPageActions } from 'store/actions/dashboard.actions';
import { dashboardFeature } from 'store/reducers/dashboard.reducers';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  pageTitle: string = "Admin Dashboard";
  readonly DataState = DataState;
  cardTitleMap: any = {
    vps: {
      "total": "Total VPSs",
      "inUse": "VPSs in use",
      "available": "Available VPSs",
    },
    subscription: {
      "registration": "Total registered",
      "subscribers": "Subscribers",
      "subscription": "Subscriptions",
      "weekRegistration": "New registrations during last 7 days",
      "weekSubscription": "New subscriptions during last 7 days",
    },
    config: {
      "VPS_BUFFER_ALERT": "Send email when available VPSs left",
    }
  };

  pageState$: Observable<DataState>;
  dashboardSummary$: Observable<DashboardSummary[]>;

  constructor(
    private store: Store,
    private configService: ConfigService,
    private toastrService: NbToastrService,
  ) {
    this.pageState$ = this.store.pipe(select(dashboardFeature.selectPageState));
    this.dashboardSummary$ = this.store.pipe(select(dashboardFeature.selectSummary));
  }

  ngOnInit(): void {
    this.store.dispatch(AdminDashboardPageActions.getDashboardInfo());
  }

  changeConfig(changeEvent: DashboardConfigChange) {
    this.configService.updateConfig$(changeEvent).subscribe({
      error: () => this.showError("Could not update config. Something went wrong"),
      complete: () => this.showSuccess("The new settings were successfully saved"),
    });
  }

  private showSuccess(message: string) {
    this.toastrService.show(message, `Success`, {
      status: 'success',
      duration: 5000,
    });
  }

  private showError(message: string) {
    this.toastrService.show(message, `Some Error Ocurred`, {
      status: 'error',
      duration: 5000,
    });
  }

}
