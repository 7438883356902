import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'videoSize'
})
export class VideoSizePipe implements PipeTransform {

  transform(bytes: number): string {
    return convertBytesToReadable(bytes);
  }
}

export function convertBytesToReadable(bytes: number) {
  const thresh = 1024;

  if (bytes < 0 || !bytes) {
    return '0 B';
  } else if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = ['kB', 'MB', 'GB', 'TB', 'PB'];
  let u = -1;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes)) >= thresh && u < units.length - 1);

  return bytes.toFixed() + ' ' + units[u];  
}