import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { userFeature } from '../../store/reducers/user.reducers';
import { User } from '../../models/user';
import { allowedEmails } from '../../consts';
import { environment } from '../../../environments/environment';
import { GiveSuggestionModalComponent } from '../give-suggestion-modal/give-suggestion-modal.component';
import { buttonsConfig } from '../../models';
import { NbWindowService } from '@nebular/theme';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  user: User;

  constructor(protected router: Router, private store: Store, private windowService: NbWindowService) {
    this.updateSidebarItems();
  }

  updateSidebarItems() {
    this.store.pipe(select(userFeature.selectUserState)).subscribe((user: any) => this.user = user)
  }

  logout() {
    // this.store.dispatch(UserActions.clearUser())
    // localStorage.removeItem('user');
    // localStorage.removeItem('sessionToken');
    document.location.href = environment.homePage;
  }

  openLandingApp() {
    document.location.href = environment.homePage;
  }

  openContactForm() {
    window.open(`${environment.homePage}/contact`, '_blank');
  }

  openSuggestionModal() {
    this.windowService.open(GiveSuggestionModalComponent, {
      title: `Give Suggestions!`,
      buttons: buttonsConfig,
      context: {
        textContent: `At Just Stream, we've designed our platform with your needs as a video content creator in mind. We really value your input and warmly invite any feedback or suggestions you might have!`,
      },
    });
  }

  isAllowedToUse() {
    return !environment.production || this.user && allowedEmails.includes(this.user?.email || ''); // TODO: delete after allow feature to all users
  }
}
