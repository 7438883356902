import { Component, Input, OnInit } from '@angular/core';
import { NbWindowRef } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { Clipboard } from '@angular/cdk/clipboard';
import { PlaylistUploadCreation } from '../../models';
import { UploadVideoSource } from '../../enum/upload-source.enum';

enum ValidationErrors {
  FILLING = "Please specify videos you want to upload",
}

@Component({
  selector: 'app-new-playlist-videos-modal',
  templateUrl: './new-playlist-videos-modal.component.html',
  styleUrls: ['./new-playlist-videos-modal.component.scss']
})
export class NewPlaylistVideosModalComponent implements OnInit {
  @Input("playlist") playlist: PlaylistUploadCreation
  onError: (message: string) => void;
  onSubmit: (...args: any) => void;

  limitUrlLinks = 30;
  validation_error: ValidationErrors;
  selectedRows: Set<string> = new Set();
  isLoading: boolean = false;
  isPlaylistShouldCreated: boolean = false;
  constructor(private windowRef: NbWindowRef, private store: Store, private clipboard: Clipboard) {
  }

  ngOnInit() {}

  submit() {
    if (!this.isFormSubmissionValid()) {
      this.onError(this.validation_error);
      return;
    }
    this.isLoading = true;
    this.onSubmit(...this.prepareDataForSubmit());
  }

  prepareDataForSubmit() {
    return [
      UploadVideoSource.YOUTUBE,
      this.prepareList(),
      this.isPlaylistShouldCreated ? this.playlist.name : undefined
    ]
  }

  prepareList() {
    return this.playlist.items.map(({ id }) => id).filter(id => this.selectedRows.has(id));
  }

  closeModal() {
    this.windowRef.close();
  }

  selectedVideosLength() {
    return [...this.selectedRows].length
  }

  creationDisabled() {
    const length = this.selectedVideosLength()
    return !length || length > 30;
  }

  isFormSubmissionValid(): boolean {
    if (this.selectedVideosLength() === 0) {
      this.validation_error = ValidationErrors.FILLING;
      return false;
    }
    return true;
  }

  togglePlaylistCreation(event: Event) {
    this.isPlaylistShouldCreated = !this.isPlaylistShouldCreated;
  }
}
