import { Pipe, PipeTransform } from '@angular/core';
import { Playlist, PlaylistCreation } from '../models/playlist';
import { VideoStatusCode } from '../enum/video-status.code';

@Pipe({
  name: 'playlistVideoLength'
})
export class PlaylistVideoLengthPipe implements PipeTransform {

  transform(playlist: Playlist | PlaylistCreation): string {
    const secondsInPlaylist = playlist.playlistVideos
      .filter(({video}) => video.deleted === false && video.statusCode === VideoStatusCode.READY_FOR_USE)
      .map(({video}) => video.length)
      .reduce((b, a) => (a || 0) + (b || 0), 0) || 0

    if (secondsInPlaylist < 60) {
      return '< 1m';
    } else {
      const hours = Math.floor(secondsInPlaylist / 3600);
      const minutes = Math.floor((secondsInPlaylist % 3600) / 60);
      const hoursString = hours > 0 ? `${hours}h ` : '';
      const minutesString = minutes > 0 ? `${minutes}m` : '';
      return `${hoursString} ${minutesString}`.trim();
    }
  }
}
