import { AxiosResponse } from 'axios';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMenuService, NbSortDirection, NbSortRequest, NbToastrService, NbWindowService } from '@nebular/theme';
import { VpsService } from '../../services/vps.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewVpsModalComponent } from '../../components/new-vps-modal/new-vps-modal.component';
import { filter, interval, map, Observable, Subscription } from 'rxjs';
import { UserService } from '../../services/user.service';
import { User } from '../../models/user';
import { DraftVps, Vps } from '../../models/vps';
import { ConfirmModalComponent } from '../../components/confirm-modal/confirm-modal.component';
import { ResourceService } from '../../services/resource.service';
import { PageChange } from 'models/events';
import { buttonsConfig } from 'models/nb';
import { LSKeys } from 'enum/local-storage-key.enum';
import { StreamService } from '../../services/stream.service';
import { VpsDetailsModalComponent } from '../../components/vps-details-modal/vps-details-modal.component';
import { WarningModalComponent } from '../../components/warning-modal/warning-modal.component';
import { VpsLogsComponent } from '../../components/vps-logs/vps-logs.component';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'vps-list',
  templateUrl: './vps-management.component.html',
  styleUrls: ['./vps-management.component.scss']
})
export class VpsManagementComponent implements OnInit, OnDestroy {

  tableColumnLabels = {
    id: 'Id',
    contabo_id: 'VPS Id',
    ip: 'IP Address',
    sv: 'Software Version',
    userEmail: 'Assigned to',
    actions: 'Actions',
  } as const;
  keysToFilter: string[] = [
    "contabo_id", "id", "ip", "softwareVersion", "status", "userEmail"
  ];
  tableColumnNames = Object.fromEntries(Object.entries(this.tableColumnLabels).map(([k, v]) => [v, k]));
  readonly allTableColumns = Object.keys(this.tableColumnLabels);

  sortColumn: string = '';
  sortRequest: NbSortRequest | null;
  sortDirection: NbSortDirection = NbSortDirection.NONE;
  private _filterString: string;

  vpsData: Vps[] = [];
  vpsUpdating: string[] = [];
  users: User[] = [];

  draftVps: DraftVps = {
    ip: '',
    userEmail: '',
    accessToken: '',
  }

  nbMenuItemsWithoutUnAssign: any[] = [
    {title: 'Details', action: this.onDetailsClick.bind(this)},
    {title: 'Edit', action: this.editVps.bind(this)},
    {title: 'Update VPS software', action: this.confirmVpsUpdate.bind(this)},
    {title: 'VPS Logs', action: this.subscribeVpsLogs.bind(this)},
    {title: 'Delete', action: this.confirmVpsDelete.bind(this)},
  ];

  nbMenuItems: any[] = [
    ...this.nbMenuItemsWithoutUnAssign,
    {title: 'Unassign', action: this.confirmUnAssignUser.bind(this)},
    {title: 'Impersonate User', action: this.impersonateUser.bind(this)},
  ];

  contextMenuTag: string = 'vps-table-context-menu';
  contextMenuActiveRow: Vps | null = null;
  menuServiceSubscription: Subscription;

  pageSize: number;
  pageNumber: number = 1;
  LSPageSizeKey = LSKeys.managementPageSize;

  vpsReloadSubscription: Observable<any>;

  constructor(
    private vpsService: VpsService,
    private nbMenuService: NbMenuService,
    private toastrService: NbToastrService,
    private modalService: NgbModal,
    private userService: UserService,
    private streamService: StreamService,
    private videoService: ResourceService,
    private windowService: NbWindowService,
  ) {
  }

  get filterString(): string {
    return this._filterString;
  }
  set filterString(value: string) {
    if (this._filterString !== value) {
      this._filterString = value;
      this.pageNumber = 1;
      this.onPageChange({ pageSize: this.pageSize, currentPage: this.pageNumber })
    }
  }

  async ngOnInit() {
    try {
      await this.fetchVpsList();
      this.vpsReloadSubscription = interval(10 * 1000)
        .pipe(map(() => this.fetchVpsList()));

      this.vpsReloadSubscription.subscribe();


      this.menuServiceSubscription = this.nbMenuService.onItemClick()
        .pipe(
          filter(({tag}) => tag === this.contextMenuTag),
          map(({item: {title}}) => title),
        )
        .subscribe(title => {
          const item = this.nbMenuItems.find((item) => item.title === title);
          if (item && item.action) item.action();
        });
    } catch {
      this.toastrService.show('Failed loading VPS list', `Error`, {status: 'warning'})
    }

    let pageSize = localStorage.getItem(LSKeys.managementPageSize) ?? '10';
    this.pageSize = parseInt(pageSize);
    this.changeSort({direction: NbSortDirection.DESCENDING, column: 'id'});
  }

   async fetchVpsList() {
    const users = await this.userService.getAll();
    const vpsList: AxiosResponse<Vps[]> = await this.vpsService.getAll();

    this.users = users.data;
    this.vpsData = vpsList.data.map(vps => {
      const user = users.data.find((user) => user.isVpsAssigned && user.assignedVps && user?.assignedVps.id === vps.id)

      return {...vps, userEmail: user?.email || ''};
    });
  }

  onDetailsClick() {
    const vpsId = this.contextMenuActiveRow?.id;
    if (vpsId) {
      this.vpsService.getVpsDetails(parseInt(vpsId)).then((response) => {
        this.windowService.open(VpsDetailsModalComponent, {
          title: `VPS Details`,
          buttons: buttonsConfig,
          context: {
            vpsDetails: response.data
          }
        });
      })
    }
  }

  isActiveVpsStatus(vps: Vps) {
    return vps?.isVpsUp && this.isActiveVpsAccessible(vps) || false;
  }

  isActiveVpsAccessible(vps: Vps) {
    return vps?.status !== 'Inaccessible';
  }

  getStatusText(vps: Vps) {
    if (this.isActiveVpsStatus(vps)) {
      return 'VPS is Active!';
    } else {
      return this.isActiveVpsAccessible(vps)
        ? 'VPS is down! Streams are not processing!'
        : 'VPS is Inaccessible! Check the configuration.';
    }
  }

  onStartUsingVps(ip: string) {
    if (ip) this.onVpsSave({ip: ip} as DraftVps);
  }

  editVps() {
    const vpsToEdit = this.contextMenuActiveRow;
    const vpsId = this.contextMenuActiveRow?.id;
    const vpsEmail = this.contextMenuActiveRow?.userEmail;

    if (vpsToEdit && vpsId) {

      this.windowService.open(NewVpsModalComponent, {
        title: `Editing VPS`,
        buttons: buttonsConfig,
        context: {
          draftVps: {
            id: vpsToEdit.id,
            ip: vpsToEdit.ip,
            userEmail: vpsToEdit.userEmail,
            accessToken: vpsToEdit.accessToken
          } as DraftVps,
          onVpsSave: this.onVpsUpdate.bind(this),
          isEditing: true,
          isAllowToUpdateEmail: vpsId && !vpsEmail
        }
      });
    }
  }

  confirmUnAssignUser() {
    this.windowService.open(ConfirmModalComponent, {
      title: `Are You Sure You Want to Un Assign user from VPS?`,
      buttons: buttonsConfig,
      context: {
        textContent: `This action will interrupt started streams, remove all videos from VPS and unassign user from VPS. Unassigning will not cancel the subscription. To delete the subscription, please do so on Wix`,
        actionCallback: () => this.unassignUser(),
        actionButtonText: 'UnAssign',
      },
    });
  }

  impersonateUser() {
    const selectedUser = this.users.find((u) => u.email?.toLowerCase() === this.contextMenuActiveRow?.userEmail.toLowerCase());

    if (selectedUser) {
      const userTabUrl = `${window.location.origin}/videos?supportMode=true&supportUserId=${selectedUser.uuid}`;
      window.open(userTabUrl, '_blank');
    }
  }

  confirmVpsUpdate() {
    this.windowService.open(ConfirmModalComponent, {
      title: `Are You Sure You Want to update Software on VPS?`,
      buttons: buttonsConfig,
      context: {
        textContent: `This action will interrupt started streams, and restart after success updating.`,
        actionCallback: () => this.updateVpsSoftware(),
        actionButtonText: 'Update',
      },
    });
  }

  confirmVpsDelete() {
    this.windowService.open(ConfirmModalComponent, {
      title: `Are You Sure You Want to delete VPS?`,
      buttons: buttonsConfig,
      context: {
        textContent: `This action will delete VPS.`,
        actionCallback: () => this.deleteVpsSoftware(),
        actionButtonText: 'Delete',
      },
    });
  }

  async unassignUser() {
    const vpsId = this.contextMenuActiveRow?.id;
    const userEmail = this.contextMenuActiveRow?.userEmail;

    if (vpsId && userEmail) {
      await this.streamService.stopUserStreams(userEmail);
      this.videoService.deleteAll$(userEmail).subscribe(() => {
        this.vpsService.unassignUserFromVps(vpsId, userEmail).then((resp) => {
          this.toastrService.success('User unassigned');
          const updatedVps = resp.data;
          this.vpsData = [...this.vpsData.filter((vps) => vps.id != updatedVps.id), updatedVps];
        }).catch(console.log);
      });
    }
  }

  async updateVpsSoftware() {
    const vpsId = this.contextMenuActiveRow?.id;
    const vps = this.vpsData.find((vps) => vps.id === vpsId);

    if (!vps)
      return;

    if ( vpsId && this.vpsUpdating.indexOf(vpsId) >= 0) {
      this.toastrService.success('VPS is currently updating!');
      return;
    }

    if (vpsId && vps.isVpsUp) {
      this.vpsUpdating.push(vpsId);
      this.toastrService.info('Updating vps software with ip ' + vps.ip + ' !');
      this.vpsService.updateVpsSoftware(vpsId).then((vps) => {
        this.toastrService.success('VPS software updated!');
      }).catch((e) => {
        console.log(e);
      }).finally(() => {
        this.vpsUpdating = this.vpsUpdating.filter((id) => id !== vpsId);
      });
    } else {
      this.toastrService.warning('Updating vps software is available while VPS is online!');
    }
  }

  async deleteVpsSoftware() {
    const vpsId = this.contextMenuActiveRow?.id;

    if (!vpsId)
      return;

    this.vpsService.deleteVps(vpsId).then((data) => {
      this.toastrService.success('VPS: ' + JSON.stringify(data));
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      this.vpsUpdating = this.vpsUpdating.filter((id) => id !== vpsId);
    });

    if (vpsId && this.vpsUpdating.indexOf(vpsId) >= 0) {
      this.toastrService.success('VPS is currently updating!');
      return;
    }
  }

  getUserIdFromEmail(email: string | undefined) {
    return this.users.find((user) => user.email === email)?.uuid;
  }

  isVpsDataValid(data: DraftVps) {
    const regexIp = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    const regexIpWithPort = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?):(6553[0-5]|655[0-2][0-9]|65[0-4][0-9][0-9]|6[0-4][0-9][0-9][0-9][0-9]|[1-5](\d){4}|[1-9](\d){0,3})$/;

    if (this.vpsData.find(value => value.ip === data.ip)?.id) {
      this.toastrService.warning('VPS already exist!');
      return false;
    } else if (data.userEmail && this.vpsData.find(value => value.userEmail === data.userEmail)?.id) {
      this.toastrService.warning('User already assigned to VPS!');
      return false;
    } else if (!(regexIp.test(data.ip) || regexIpWithPort.test(data.ip) || !environment.production)) {
      this.toastrService.warning('VPS ip is invalid!');
      return false
    }

    return true;
  }

  isVpsDataValidForUpdate(data: DraftVps) {
    const regexIp = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    const regexIpWithPort = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?):(6553[0-5]|655[0-2][0-9]|65[0-4][0-9][0-9]|6[0-4][0-9][0-9][0-9][0-9]|[1-5](\d){4}|[1-9](\d){0,3})$/;

    const vpsForUpdate = this.vpsData.find(value => value.ip === data.ip);
    if (vpsForUpdate && vpsForUpdate?.id && data?.id !== vpsForUpdate?.id) {
      this.toastrService.warning('VPS already exist!');
      return false;
    } else if (data.userEmail && this.vpsData.find(value => value.userEmail === data.userEmail)?.id) {
      this.toastrService.warning('User already assigned to VPS!');
      return false;
    } else if (!(regexIp.test(data.ip) || regexIpWithPort.test(data.ip) || !environment.production)) {
      this.toastrService.warning('VPS ip is invalid!');
      return false
    }

    return true
  }

  onVpsSave(data: DraftVps): Promise<any> {
    if (!this.isVpsDataValid(data)) {
      return Promise.reject();
    }

    this.modalService.dismissAll()
    this.toastrService.success('Started creating VPS ' + data.ip + ' !');

    return this.vpsService.createNewVps(data).then((newVpsData: AxiosResponse<Vps>) => {
      this.toastrService.success('Created VPS ' + data.ip + ' !');
      this.vpsData = [newVpsData.data, ...this.vpsData];
      this.modalService.dismissAll();
      return Promise.resolve();
    }).catch((e) => {
      console.log(e);
      this.toastrService.warning('Problem with adding new VPS ' + data.ip);
    })
  }

  onVpsUpdate(data: DraftVps) {
    if (!this.isVpsDataValidForUpdate(data)) {
      return Promise.reject();
    }
    const vpsToEdit = this.vpsData.find((vps) => vps.id == this.contextMenuActiveRow?.id);

    if (vpsToEdit && vpsToEdit.id) {
      this.modalService.dismissAll();
      this.toastrService.success('Started updating VPS ' + vpsToEdit.ip + ' !');

      const updatedVps: DraftVps = {...vpsToEdit, ip: data.ip, userEmail: data.userEmail, accessToken: data.accessToken}
      return this.vpsService.updateVps(updatedVps).then((updatedVpsData: AxiosResponse<Vps>) => {
        this.vpsData = [updatedVpsData.data, ...this.vpsData.filter((vps) => vps.id != updatedVps.id)];
        this.toastrService.success('VPS ' + vpsToEdit.ip + ' updated!');
      }).catch((e) => {
        console.log(e);
        this.toastrService.warning(e);
        this.toastrService.success('VPS ' + vpsToEdit.ip + ' failed updating!');
      })
    }
    return Promise.reject();
  }

  openNewVpsModal() {
    this.windowService.open(NewVpsModalComponent, {
      title: `Configure VPN`,
      buttons: buttonsConfig,
      context: {draftVps: this.draftVps, isAllowToUpdateEmail: true, onVpsSave: this.onVpsSave.bind(this)}
    });
  }

  subscribeVpsLogs() {
    const vpsId = this.contextMenuActiveRow?.id;

    if (!vpsId)
      return;

    this.vpsService.getVpsLogs(vpsId).then((data) => {
      let modalRef = this.windowService.open(WarningModalComponent, {
        title: `Vps Logs`,
        buttons: buttonsConfig,
        windowClass: 'full-screen-modal',
        context: {
          actionButtonBlocked: true,
          customComponent: VpsLogsComponent,
          customComponentParams: {
            logs: data,
          },
          fullWidth: true,
        },
      });
    }).catch((e) => {
      console.log(e);
    })
  }

  changeSort(sortRequest: NbSortRequest): void {
    this.sortColumn = sortRequest.column;
    this.sortRequest = sortRequest;
    this.sortDirection = sortRequest.direction;
  }

  getDirection(column: string): NbSortDirection {
    if (column === this.sortColumn) {
      return this.sortDirection;
    }
    return NbSortDirection.NONE;
  }

  onPageChange(pageEvent: PageChange) {
    this.pageSize = pageEvent.pageSize;
    this.pageNumber = pageEvent.currentPage;
  }

  ngOnDestroy(): void {
    this.menuServiceSubscription.unsubscribe();
    this.vpsReloadSubscription.subscribe();
  }
}
