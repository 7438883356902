import { Component, Input } from '@angular/core';

@Component({
  template: `
    <nb-tag
      appearance="filled"
      status="primary"
      class="unselectable"
      [text]="text"
      [ngStyle]="{
        backgroundColor: color % 2 ? 'var(--dim-grey)' : 'var(--main-orange)',
        border: 'none'
      }"
    ></nb-tag>
  `,
  selector: 'app-label',
})
export class PageLabelComponent {
  @Input('color-schema') color: number;
  @Input('text') text: string;
}
