<div class="vps-modal-container">
  <div class="vps-modal-field">
    <label>VPS IP*</label>
    <nb-form-field style="align-items: flex-start;">
      <input
        nbInput
        fieldSize="large"
        class="input-full-width"
        [value]="draftVps.ip"
        (input)="onUpdateField('ip', vpsIdentifierInput.value)"
        #vpsIdentifierInput
        [disabled]="!isAllowToUpdateEmail"
        placeholder="Enter VPS IP Address like 192.168.1.1"
        autocomplete="no"
      >
    </nb-form-field>
  </div>
  <div class="vps-modal-field">
    <label>Access Token</label>
    <nb-form-field style="align-items: flex-start;">
      <input
        nbInput
        fieldSize="large"
        class="input-full-width {{isConnectionToVpsSuccess != null ? 'mb-0' : ''}}"
        placeholder="Enter Access Token"
        [type]="showAccessKey ? 'text' : 'password'"
        [value]="draftVps.accessToken || ''"
        (change)="onUpdateField('accessToken', accessTokenInput.value)"
        #accessTokenInput
        autocomplete="new-password"
      >
      <button nbSuffix nbButton ghost (click)="showAccessKey = !showAccessKey" [nbSpinner]="showLoadingConnectionStatus"
              nbSpinnerStatus="success">
        <nb-icon [icon]="showAccessKey ? 'eye-outline' : 'eye-off-outline'">
        </nb-icon>
      </button>
      <label *ngIf="isConnectionToVpsSuccess != null"
             class="nb-input-label mb-2"
             style="font-size:12px">
        {{isConnectionToVpsSuccess ? "Connection success" : "Failed to connect!"}}
      </label>
    </nb-form-field>
  </div>
  <div class="vps-modal-field">
    <label>User mail to assign</label>
    <nb-form-field style="align-items: flex-start;">
      <input
        nbInput
        fieldSize="large"
        class="input-full-width"
        [value]='draftVps.userEmail || ""'
        (input)="onUpdateField('userEmail', userEmailInput.value)"
        [disabled]="!isAllowToUpdateEmail"
        #userEmailInput
        placeholder="Enter user mail">
      <label *ngIf="!isAllowToUpdateEmail"
             class="nb-input-label mb-2"
             style="font-size:12px; color: #e10000">
        To change disabled fields, first unassign current user.
      </label>
    </nb-form-field>
  </div>

</div>

<app-modal-action-buttons
  [actionCallback]="submitForm.bind(this)"
  [actionButtonDisabled]="draftVps.ip.length === 0"
  [cancelCallback]="closeModal.bind(this)"
>
</app-modal-action-buttons>
