<div class="character-form">
  <form [formGroup]="characterForm" (ngSubmit)="submit()">
    <div class="form-group character-form-section">
      <label for="character">Character</label>
      <app-search-select [options$]="charactersOption$" id="character" formControlName="character" [placeholder]="'Select character'"/>
    </div>

    <div class="form-group">
      <div class="character-form-input-label">
        <label for="scenario">Scenario</label>
        Tokens: {{ scenarioTokens }}
      </div>
      <textarea
        id="scenario"
        placeholder="type scenario of character"
        formControlName="scenario"
        class="form-control character-form-input"
        maxlength="1500"
        rows="20"
      >
      </textarea>
    </div>

    <app-modal-action-buttons
      [action-button-text]="'Save'"
      [actionCallback]="submit.bind(this)"
      [cancelCallback]="closeModal.bind(this)"
      [action-button-loading]="isLoading"
    />
  </form>
</div>
